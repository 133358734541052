import React from "react";
import classnames from "classnames";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { clickHandler, noop, swallow } from "@util";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface ProjectViewActionsMenuModel {
  className?: string;
  actionDisabled?: boolean;
}

export interface ProjectViewActionsMenuActions {
  addFile?: () => void;
  addFolder?: () => void;
}

type Model = ProjectViewActionsMenuModel;
type Actions = ProjectViewActionsMenuActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ProjectViewActionsMenu = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    actionDisabled,
    addFile = noop,
    addFolder = noop,
    children,
  } = props;

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [open, setOpen] = React.useState(false);

  const openMenu = clickHandler(React.useCallback(() => setOpen(true), [setOpen]));

  const handleClose = React.useCallback(event => {
    swallow(event);
    const currentTarget = anchorRef && anchorRef.current !== null ? anchorRef.current : null;
    if (currentTarget !== null && currentTarget.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, [anchorRef, setOpen]);

  const onClickAddFile = React.useCallback(event => {
    swallow(event);
    addFile();
    handleClose(event);
  }, [addFile, handleClose]);

  const onClickAddFolder = React.useCallback(event => {
    swallow(event);
    addFolder();
    handleClose(event);
  }, [addFolder, handleClose]);

  return (
    <div className={classnames("projectViewActionsMenu", className, classes.container)}>
      <IconButton
        ref={anchorRef}
        className={classnames("addIconButton", classes.addIconButton)}
        size="small"
        disabled={actionDisabled}
        onClick={openMenu}
      >
        <AddIcon className={classnames("addIcon", classes.addIcon)} />
      </IconButton>
      <Popper
        className={classnames("popper", classes.popper)}
        open={open}
        transition={true}
        disablePortal={true}
        placement="right-start"
        anchorEl={anchorRef.current}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper
              className={classnames("paper", classes.paper)}
            >
              <ClickAwayListener
                onClickAway={handleClose}
              >
                <MenuList
                  className={classnames("list", classes.list)}
                  autoFocusItem={open}
                >
                  <MenuItem
                    className={classnames("listItem", "addFile", classes.listItem, classes.addFile)}
                    onClick={onClickAddFile}
                  >
                    Add File
                  </MenuItem>
                  <MenuItem
                    className={classnames("listItem", "addFolder", classes.listItem, classes.addFolder)}
                    onClick={onClickAddFolder}
                  >
                    Add Folder
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {children}
    </div>
  );
});

export default ProjectViewActionsMenu;
import React from "react";
import classnames from "classnames";
import { SearchFilter } from "@data";
import { isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AppliedFilters, DataWorkloadFilters, WorkloadsFiltersMenu } from "@components";
import WorkloadsListView, {
  WorkloadsListViewActions,
  WorkloadsListViewItem,
  WorkloadsListViewModel,
} from "./WorkloadsListView";
import styles from "./styles";

const mapSearchFilterToLabel = (searchFilter: SearchFilter) => {
  switch (searchFilter.getKey()) {
    case DataWorkloadFilters.RELEASED_FILTER:
      return "State: Released";
    case DataWorkloadFilters.PRINCIPAL_FILTER:
      return `Principal: ${searchFilter.getValue()}`;
    default:
      return "No Filters";
  }
};

export interface WorkloadsListModel extends WorkloadsListViewModel {
  className?: string;
  nameFilter?: string;
  releasedByNameFilter?: string;
  filterByReleasedState?: boolean;
  filtersMenuDisabled?: boolean;
  showSummaryViewFiltersMenu?: boolean;
  workloads?: WorkloadsListViewItem[];
  selectedWorkloads?: WorkloadsListViewItem[];
  excludedWorkloads?: WorkloadsListViewItem[];
}

export interface WorkloadsListActions extends WorkloadsListViewActions {
  createWorkload?: () => void;
  showWorkloadDetails?: (name: string) => void;
  setSelectedWorkloads?: (selectedWorkloads: WorkloadsListViewItem[]) => void;
}

type Props = WithStyles<typeof styles> & WorkloadsListModel & WorkloadsListActions;

export const WorkloadsList = withStyles(styles)((props: Props) => {

  const {
    classes,
    filtersMenuDisabled,
    showSummaryViewFiltersMenu = true,
    filterByReleasedState: initialFilterByReleasedState = false,
    releasedByNameFilter: initialReleasedByNameFilter = "",
    summaryViewLabel = "Workloads",
    createWorkload,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState("");

  const [showFiltersMenu, setShowFiltersMenu] = React.useState(false);

  const [releasedByNameFilter, setReleasedByNameFilter] = React.useState(initialReleasedByNameFilter);

  const [filterByReleasedState, setFilterByReleasedState] = React.useState(initialFilterByReleasedState);

  const [loadingIndicatorVisible, setLoadingIndicatorVisible] = React.useState(false);

  const onChangeShowLoadingIndicator = React.useCallback((loading: boolean) => {
    setLoadingIndicatorVisible(loading);
  }, [setLoadingIndicatorVisible]);

  const setFilterByReleased = React.useCallback((value: boolean) => {
    setFilterByReleasedState(value);
  }, [setFilterByReleasedState, setReleasedByNameFilter]);

  const setReleasedByName = React.useCallback((value: string) => {
    setReleasedByNameFilter(value);
  }, [setReleasedByNameFilter]);

  React.useEffect(() => {
    setFilterByReleasedState(!isEmptyString(releasedByNameFilter));
  }, [releasedByNameFilter, setFilterByReleasedState]);

  React.useEffect(() => {
    if (!filterByReleasedState) {
      setReleasedByNameFilter("");
    }
  }, [filterByReleasedState, setReleasedByNameFilter]);

  const searchFilters = React.useMemo<SearchFilter[]>(() => ([] as SearchFilter[])
    .concat(filterByReleasedState ? [new SearchFilter({
      key: DataWorkloadFilters.RELEASED_FILTER
    })] : [])
    .concat(!isEmptyString(releasedByNameFilter)
      ? ([new SearchFilter({
        key: DataWorkloadFilters.PRINCIPAL_FILTER,
        value: releasedByNameFilter,
      })]) : []), [
    filterByReleasedState,
    releasedByNameFilter
  ]);

  const badgeCount = React.useMemo(() => searchFilters.length, [searchFilters]);

  const openMenu = React.useCallback(() => setShowFiltersMenu(true), [setShowFiltersMenu]);

  const closeMenu = React.useCallback(() => setShowFiltersMenu(false), [setShowFiltersMenu]);

  const setSearchFilters = React.useCallback((updatedSearchFilters: SearchFilter[]) =>
    searchFilters
      .filter(it => !updatedSearchFilters.some(it2 => it.equals(it2)))
      .map(it => it.getKey())
      .forEach(type => {
        switch (type) {
          case DataWorkloadFilters.RELEASED_FILTER:
            setFilterByReleasedState(false);
            break;
          case DataWorkloadFilters.PRINCIPAL_FILTER:
            setReleasedByNameFilter("");
            break;
          default:
            break;
        }
      }), [
    searchFilters,
    setFilterByReleasedState,
    setReleasedByNameFilter,
  ]);

  const clearFilters = React.useCallback(() => {
    setFilterByReleasedState(false);
    setReleasedByNameFilter("");
  }, [
    setFilterByReleasedState,
    setReleasedByNameFilter,
  ]);

  const header = React.useMemo(() => !showSummaryViewFiltersMenu ? null : (
    <AppliedFilters
      className={classnames("appliedFilters", classes.appliedFilters)}
      disabled={loadingIndicatorVisible || filtersMenuDisabled}
      searchFilters={searchFilters}
      mapSearchFilterToLabel={mapSearchFilterToLabel}
      setSearchFilters={setSearchFilters}
      onClickSearchFilter={openMenu}
      clearFilters={clearFilters}
    />
  ), [
    showSummaryViewFiltersMenu,
    classes,
    loadingIndicatorVisible,
    filtersMenuDisabled,
    searchFilters,
    mapSearchFilterToLabel,
    setSearchFilters,
    openMenu,
    clearFilters,
  ]);

  const summaryViewFiltersMenu = React.useMemo(() => !showSummaryViewFiltersMenu ? null : (
    <WorkloadsFiltersMenu
      className={classnames("filtersMenu", classes.filtersMenu)}
      badgeCount={badgeCount}
      disabled={loadingIndicatorVisible || filtersMenuDisabled}
      clearButtonDisabled={badgeCount === 0}
      filterByReleasedState={filterByReleasedState}
      releasedByNameFilter={releasedByNameFilter}
      open={showFiltersMenu}
      openMenu={openMenu}
      closeMenu={closeMenu}
      clearFilters={clearFilters}
      setFilterByReleasedState={setFilterByReleased}
      setReleasedByNameFilter={setReleasedByName}
    />
  ), [
    showSummaryViewFiltersMenu,
    classes,
    badgeCount,
    loadingIndicatorVisible,
    filtersMenuDisabled,
    filterByReleasedState,
    releasedByNameFilter,
    showFiltersMenu,
    openMenu,
    closeMenu,
    clearFilters,
    setFilterByReleased,
    setReleasedByName,
  ]);

  return (
    <WorkloadsListView
      {...otherProps}
      showSummary={true}
      showSearch={true}
      header={header}
      nameFilter={nameFilter}
      releasedByNameFilter={releasedByNameFilter}
      filterByReleasedState={filterByReleasedState}
      searchFilters={searchFilters}
      summaryViewLabel={summaryViewLabel}
      summaryViewFiltersMenu={summaryViewFiltersMenu}
      setNameFilter={setNameFilter}
      onClickCreateButton={createWorkload}
      onChangeShowLoadingIndicator={onChangeShowLoadingIndicator}
    />
  );
});

export default WorkloadsList;

import React from "react";
import "brace/theme/xcode";
import "brace/mode/python";
import "brace/ext/searchbox";
import "brace/snippets/python";
import "brace/ext/language_tools";
import classnames from "classnames";
import AceEditor, { AceOptions, EditorProps } from "react-ace";
import { Editor } from "brace";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export const DEFAULT_EDITOR_PROPS = {
  $blockScrolling: true,
};

export const DEFAULT_OPTIONS = {
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  enableSnippets: true,
  showLineNumbers: true,
  tabSize: 2,
  scrollSpeed: 0.75,
};

export interface CodeEditorModel {
  className?: string;
  editorClassName?: string;
  name?: string;
  mode?: "python" | "javascript";
  code?: string;
  width?: string;
  height?: string;
  readOnly?: boolean;
  tabSize?: number;
  fontSize?: number;
  showGutter?: boolean;
  showPrintMargin?: boolean;
  highlightActiveLine?: boolean;
  debounceChangePeriod?: number;
  editorProps?: EditorProps;
  aceOptions?: AceOptions;
}

export interface CodeEditorActions {
  setCode?: (code: string) => void;
}

type Model = CodeEditorModel;
type Actions = CodeEditorActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CodeEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    editorClassName,
    name = "codeEditor",
    mode = "python",
    code = "",
    width = "auto",
    height: initialHeight = "auto",
    readOnly,
    tabSize = 2,
    fontSize = 12,
    showGutter = true,
    showPrintMargin = false,
    highlightActiveLine = true,
    debounceChangePeriod = 500,
    editorProps = DEFAULT_EDITOR_PROPS,
    aceOptions = DEFAULT_OPTIONS,
    setCode,
  } = props;

  const [height, setHeight] = React.useState(initialHeight);
  const editorRef = React.useRef<Editor | null>(null);

  const updateHeight = React.useCallback(node => {
    if (initialHeight === "auto" && node !== null) {
      setHeight(`${node.getBoundingClientRect().height}px`);
    }
  }, [initialHeight, setHeight]);

  const onLoad = (editor: Editor) => {
    editorRef.current = editor;
    editor.navigateFileEnd();
  };

  React.useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, [code]);

  return (
    <div
      ref={updateHeight}
      className={classnames("codeEditor", className, classes.container)}
    >
      <AceEditor
        className={classnames("editor", editorClassName, classes.editor)}
        name={name}
        mode={mode}
        theme="xcode"
        value={code}
        width={width}
        height={height}
        tabSize={tabSize}
        readOnly={readOnly}
        fontSize={fontSize}
        showGutter={showGutter}
        showPrintMargin={showPrintMargin}
        highlightActiveLine={!readOnly && highlightActiveLine}
        debounceChangePeriod={debounceChangePeriod}
        editorProps={editorProps}
        setOptions={aceOptions}
        onLoad={onLoad}
        onChange={setCode}
      />
    </div>
  );
});

export default CodeEditor;
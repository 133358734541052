import { UseApiRequestProps, useUpdatePreference } from "@hooks";
import { useDispatch } from "react-redux";
import { updateFavoriteModules } from "@main/actions";
import { openBroadcastChannel } from "@util";
import { BROADCAST_CHANNEL_UPDATE_FAVORITES } from "@store/constants";

type SuccessResponse = () => void;

export interface UseUpdateFavoritesProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  newFavorites: string[];
}

export const useUpdateFavorites = (props: UseUpdateFavoritesProps): SuccessResponse => {

  const {
    newFavorites,
    deferRequest,
  } = props;

  const dispatch = useDispatch();

  const { postMessage } = openBroadcastChannel(BROADCAST_CHANNEL_UPDATE_FAVORITES);

  const [, { refresh }] =
    useUpdatePreference({
      deferRequest,
      preferenceName: "favorites",
      preferenceValue: JSON.stringify({ modules: newFavorites }),
      onSuccess: (p) => {
        dispatch(updateFavoriteModules(p.entityValue.modules));
        postMessage({favorites: p.entityValue.modules});
      }
    });

  return refresh;
};

export default useUpdateFavorites;

import { useApiRequestAnalytics } from "@hooks";
import { GetUiPreferenceResponse, UiPersonalizationClient } from "@network";

export type GetPreferenceApi = (preferenceName: string) => Promise<GetUiPreferenceResponse>;
export type UpdatePreferenceApi = (preferenceName: string, preferenceValue: string) => Promise<GetUiPreferenceResponse>;

export interface UiPersonalizationClientApi {
  getPreference: GetPreferenceApi;
  updatePreference: UpdatePreferenceApi;
}

enum ActionTypes {
  GET_PREFERENCE_REQUEST = "ui_personalization_client/get_preference_request",
  GET_PREFERENCE_SUCCESS = "ui_personalization_client/get_preference_success",
  GET_PREFERENCE_FAILED = "ui_personalization_client/get_preference_failed",

  UPDATE_PREFERENCE_REQUEST = "ui_personalization_client/update_preference_request",
  UPDATE_PREFERENCE_SUCCESS = "ui_personalization_client/update_preference_success",
  UPDATE_PREFERENCE_FAILED = "ui_personalization_client/update_preference_failed",
}

export const useGetPreferenceApi = (): GetPreferenceApi =>
  useApiRequestAnalytics(UiPersonalizationClient.getPreference, {
    REQUEST_EVENT: ActionTypes.GET_PREFERENCE_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_PREFERENCE_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_PREFERENCE_FAILED,
  });

export const useUpdatePreferenceApi = (): UpdatePreferenceApi =>
  useApiRequestAnalytics(UiPersonalizationClient.updatePreference, {
    REQUEST_EVENT: ActionTypes.UPDATE_PREFERENCE_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPDATE_PREFERENCE_SUCCESS,
    ERROR_EVENT: ActionTypes.UPDATE_PREFERENCE_FAILED,
  });

export const useUiPersonalizationClient = (): UiPersonalizationClientApi => ({
  getPreference: useGetPreferenceApi(),
  updatePreference: useUpdatePreferenceApi(),
});

export { ActionTypes as UiPersonalizationClientActionType };

export default useUiPersonalizationClient;

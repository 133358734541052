import React from "react";
import usePaginatedApiRequest, {
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks/use-paginated-api-request";
import WorkloadsListViewItem, { WorkloadsListViewItemAttributes } from "./WorkloadsListViewItem";
import useWorkloadManagerClient from "../../../hooks/workloadsManager/use-workload-manager-client";
import WorkloadSummary from "@data/WorkloadSummary";
import ReleasedWorkloadIdentity from "@data/ReleasedWorkloadIdentity";

type SuccessResponse = {
  items: WorkloadsListViewItemAttributes[];
  paging: {
    next?: string;
  }
};

export interface UseGetWorkloadsListViewItemsProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  nameFilter?: string;
  releasedByNameFilter?: string;
  filterByReleasedState?: boolean;
  workloads?: WorkloadsListViewItem[];
}

export interface UseGetWorkloadsListViewItemsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  workloads: WorkloadsListViewItem[];
}

export interface UseGetWorkloadsListViewItemsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseGetWorkloadsListViewItemsProps;
type Model = UseGetWorkloadsListViewItemsModel;
type Actions = UseGetWorkloadsListViewItemsActions;
type Result = [Model, Actions];

export const useGetWorkloadsListViewItems = (props: Props = {}): Result => {

  const {
    nameFilter,
    releasedByNameFilter,
    workloads: initialWorkloads = [],
    filterByReleasedState,
    defaultErrorMessage = React.useMemo(() =>
        filterByReleasedState ? "Failed to get released workloads" : "Failed to get workloads",
      [filterByReleasedState]),
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const [workloads, setWorkloads] = React.useState<WorkloadsListViewItem[]>(initialWorkloads);

  const makeApiRequest = React.useCallback((_accessToken, next) => {
    if (filterByReleasedState) {
      return WorkloadManagerClient.getReleasedWorkloadsApi(nameFilter, releasedByNameFilter, next)
        .then(({ items = [], ...response })  => ({
          ...response,
          items: items.map(attrs =>
            WorkloadsListViewItem.fromReleasedWorkloadIdentity(
              new ReleasedWorkloadIdentity(attrs)).toJS())
        }));
    }
    return WorkloadManagerClient.getWorkloadsApi(nameFilter, next)
      .then(({ items = [], ...response })  => ({
        ...response,
        items: items.map(attrs =>
          WorkloadsListViewItem.fromWorkloadSummary(
            new WorkloadSummary(attrs)).toJS())
      }));
  }, [WorkloadManagerClient, nameFilter, releasedByNameFilter, filterByReleasedState]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedWorkloads = React.useMemo<WorkloadsListViewItem[]>(() => {
    const { items = [] } = successResponse ? successResponse : {};
    return items.map(attrs => new WorkloadsListViewItem(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setWorkloads([]);
    baseReset();
  }, [setWorkloads, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  React.useEffect(() => {
    refresh();
  }, [
    refresh,
    nameFilter,
    releasedByNameFilter,
    filterByReleasedState,
  ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    workloads,
  }), [
    baseModel,
    workloads,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setWorkloads(currentWorkloads => currentWorkloads.concat(updatedWorkloads));
  }, [setWorkloads, updatedWorkloads]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloadsListViewItems;

import React from "react";
import { isEmptyString, noop } from "@util";
import { Model as TriggerInfoModel, TriggerInfo } from "../components/TriggerInfo";
import { Trigger, TriggerType, UpdateTriggerRequest } from "@data";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import { useAddTrigger, useGetWorkload, useUpdateWorkloadTrigger } from "@hooks";
import { useLocation } from "react-router-dom";
import { DATA_PROCESSING_DEVELOPER_GUIDE } from "@modules/dataWorkloads/constants";
import { AccountIdContext } from "@components";

interface ContainerModel extends SubmitApiRequestViewModel<Trigger>, TriggerInfoModel {
  name: string;
  version: number;
  type: TriggerType;
  isEdit?: boolean;
}

interface ContainerActions extends SubmitApiRequestViewActions<Trigger> {
  showWorkloadDetails?: (id: string) => void;
}

type Props = ContainerModel & ContainerActions;

const TriggerWizardContainer = (props: Props) => {

  const {
    name,
    version,
    type,
    isEdit = false,
    showWorkloadDetails = noop,
    ...otherProps
  } = props;

  const accountId = React.useContext(AccountIdContext);

  const [data, setData] = React.useState<UpdateTriggerRequest>(
    new UpdateTriggerRequest({
      eventType: type,
      type,
      accountId,
    }));

  const [error, setError] = React.useState("");

  const [{workload}] = useGetWorkload({ name, version });

  const [{
    triggerId,
    showSuccessView,
    errorMessage,
    successMessage,
    ...otherModel
  }, {
    addTrigger
  }] = useAddTrigger({ name, version, data });

  const [{
    showSuccessView: showUpdateSuccess,
    successMessage: updateSuccessMessage,
    errorMessage: updateErrorMessage,
    ...otherUpdateModel
  }, {
    updateTrigger
  }] = useUpdateWorkloadTrigger({ name, version, trigger: data });

  // get current trigger
  const location = useLocation<any>();
  const currentTrigger = React.useMemo(() =>
    location.state ? location.state.trigger ? new Trigger(location.state.trigger) : Trigger.EMPTY : Trigger.EMPTY
    , [location]);

  const operation = isEdit ? "Update" : "Add";

  const setTriggerData = React.useCallback((obj: UpdateTriggerRequest) => {
    setError("");
    setData(
      new UpdateTriggerRequest({
        ...data.toJS(),
        ...obj,
      }));
    }, [data, setData, setError]);

  const expression = React.useMemo(() => data.scheduleExpression, [data]);

  const cronValues = React.useMemo(() =>
    expression.includes("cron") ? expression.substring(
      expression.indexOf("(") + 1, expression.indexOf(")")
    ) : "* * * * * *", [expression]);

  const validRateMinutes = React.useMemo(() => {
    return !(expression.match(/rate\([0-9] minutes?\)/));
  }, [expression]);

  const validTrigger = React.useMemo(() => {
    if (data.getType() === TriggerType.DATA_LAKE) {
      return !isEmptyString(data.getAccountId()) && !isEmptyString(data.getDataSetAlias());
    }

    if (data.getType() === TriggerType.WORKLOAD_COMPLETE) {
      return !isEmptyString(data.getPrecedingWorkloadName()) && !isEmptyString(data.getAccountId());
    }

    // Reject rate expressions between minutes 1-9
    if (!validRateMinutes) {
      return false;
    }

    // Reject cron expressions without a minute or hour selected
    if (expression.includes("cron")) {
      const values = cronValues.split(" ");

      return !(values[0] === "*" || values[1] === "*");
    }

    return true;
  }, [data, expression, cronValues, validRateMinutes]);

  React.useEffect(() => {
    setError(isEdit ? updateErrorMessage : errorMessage);
  }, [isEdit, updateErrorMessage, errorMessage]);

  React.useEffect(() => {
    if (isEdit) {
      if (currentTrigger !== undefined && !isEmptyString(currentTrigger.getId())) {
        setData(new UpdateTriggerRequest({
          eventBindingId: currentTrigger.getId(),
          eventType: currentTrigger.getType(),
          type: currentTrigger.getType(),
        }));
      }
    }
  }, [isEdit, currentTrigger]);

  const model = React.useMemo(() => isEdit ? otherUpdateModel : otherModel, [isEdit, otherUpdateModel, otherModel]);

  return (
    <SubmitApiRequestView
      {...model}
      {...otherProps}
      snackbarId={operation + "-trigger"}
      errorTitle={operation + " Trigger Failed"}
      saveButtonLabel={operation + " Trigger"}
      currentState={new UpdateTriggerRequest(data)}
      defaultState={Trigger.EMPTY}
      onSuccess={() => showWorkloadDetails(name, version)}
      saveButtonDisabled={!validTrigger}
      showSuccessIndicator={isEdit ? showUpdateSuccess : showSuccessView}
      successMessage={isEdit ? updateSuccessMessage : successMessage}
      errorMessage={error}
      save={isEdit ? updateTrigger : addTrigger}
      documentationLink={DATA_PROCESSING_DEVELOPER_GUIDE}
    >
      <TriggerInfo
        name={name}
        version={version}
        isEdit={isEdit}
        data={data}
        setTriggerData={setTriggerData}
        dataInputs={workload.getDataInputs()}
        validRateMinutes={validRateMinutes}
        cronValues={cronValues}
        trigger={currentTrigger}
      />
    </SubmitApiRequestView>
  );
};

export default TriggerWizardContainer;

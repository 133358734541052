import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
  useUiPersonalizationClient
} from "@hooks";
import { GetUiPreferenceResponse } from "@network";

type SuccessResponse = GetUiPreferenceResponse;

export interface UseUpdatePreferenceProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  preferenceName: string;
  preferenceValue: string;
}

export interface UseUpdatePreferenceModel extends UseApiRequestModel<SuccessResponse> {
  preference: string[];
}

export interface UseUpdatePreferenceActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseUpdatePreferenceProps;
type Model = UseUpdatePreferenceModel;
type Actions = UseUpdatePreferenceActions;
type Result = [Model, Actions];

export const useUpdatePreference = (props: Props): Result => {

  const {
    deferRequest = true,
    preferenceName,
    preferenceValue,
    defaultErrorMessage = "Failed to update preference",
    ...otherProps
  } = props;

  const UiPersonalizationClient = useUiPersonalizationClient();

  const makeApiRequest = React.useCallback(
    () => UiPersonalizationClient.updatePreference(preferenceName, preferenceValue),
    [UiPersonalizationClient, preferenceName, preferenceValue]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest,
    });

  const preference = React.useMemo<string[]>(() => {
    return successResponse ? successResponse.entityValue.modules : [];
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    preference,
  }), [
    baseModel,
    preference,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUpdatePreference;

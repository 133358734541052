import React from "react";
import { noop } from "@util";
import styles from "./styles";
import classnames from "classnames";
import { useCopyWorkloadCode } from "@hooks";
import { WorkloadPythonCodeProperty } from "@data";
import { CodeInfo } from "../CreateWorkloadWizardView";
import CreateWorkloadRequest from "@data/CreateWorkloadRequest";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useCreateWorkload } from "@hooks/workloadsManager/use-create-workload";
import { DATA_PROCESSING_DEVELOPER_GUIDE } from "@modules/dataWorkloads/constants";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";

export enum CreateWorkloadStep {
  NONE = "",
  INFO = "info",
  TYPE = "type",
  DATA_SOURCES = "data_sources",
  DATA_OUTPUTS = "data_outputs",
  QUERY_EDITOR = "query_editor",
  COMPLETION_METADATA = "completion_metadata",
  INTERNET_ACCESS = "internet_access",
  REVIEW = "review",
}

export const DEFAULT_STEP_LABELS = {
  [CreateWorkloadStep.INFO]: "Information",
  [CreateWorkloadStep.TYPE]: "Type",
  [CreateWorkloadStep.DATA_SOURCES]: "Data Set Inputs",
  [CreateWorkloadStep.QUERY_EDITOR]: "Query Editor",
  [CreateWorkloadStep.DATA_OUTPUTS]: "Data Set Outputs",
  [CreateWorkloadStep.COMPLETION_METADATA]: "Completion Metadata",
  [CreateWorkloadStep.INTERNET_ACCESS]: "Internet Access",
  [CreateWorkloadStep.REVIEW]: "Finish",
};

export const DEFAULT_STEPS = [
  CreateWorkloadStep.INFO,
  CreateWorkloadStep.TYPE,
  CreateWorkloadStep.DATA_SOURCES,
  CreateWorkloadStep.QUERY_EDITOR,
  CreateWorkloadStep.DATA_OUTPUTS,
  CreateWorkloadStep.COMPLETION_METADATA,
  CreateWorkloadStep.INTERNET_ACCESS,
  CreateWorkloadStep.REVIEW,
];

export interface Model extends CodeInfo, SubmitApiRequestViewModel<CreateWorkloadRequest, CreateWorkloadStep> {
  data?: CreateWorkloadRequest;
  codeProperties?: WorkloadPythonCodeProperty;
  stepLabels?: { [key: string]: string };
}

export interface Actions extends SubmitApiRequestViewActions<CreateWorkloadRequest, CreateWorkloadStep> {
  showWorkloadDetails?: (name: string, version?: number | "latest") => void;
  setCodeProperties?: (props: Partial<WorkloadPythonCodeProperty>) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataWorkloadWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    sourceName = "",
    sourceVersion = 1,
    disabledSteps = [],
    containsCode = false,
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    data = CreateWorkloadRequest.EMPTY,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [ stepLabels ]),
    showWorkloadDetails = noop,
    children,
    ...otherProps
  } = props;

  const [{ name, version, ...model }, { createWorkload, reset, ...actions }] =
    useCreateWorkload({ workload: data });

  const [{ }, { copyCode }] = useCopyWorkloadCode({ name, version, sourceName, sourceVersion });

  const onSuccess = React.useCallback(() => {
    if (containsCode) {
      copyCode();
    }
    showWorkloadDetails(name, version);
  }, [name, version, sourceName, sourceVersion, containsCode, copyCode, showWorkloadDetails]);

  const filteredSteps = React.useMemo(() => {
    return data.isQueryType() ? steps : steps.filter(step =>
      step !== CreateWorkloadStep.QUERY_EDITOR);
  }, [data, steps]);

  return (
    <SubmitApiRequestView
      {...model}
      {...actions}
      {...otherProps}
      className={classnames("createWorkload", classes.container)}
      snackbarId="create-workload"
      errorTitle="Create Workload Failed"
      saveButtonLabel="Create Workload"
      steps={filteredSteps}
      mapStepToLabel={mapStepToLabel}
      currentState={data}
      defaultState={CreateWorkloadRequest.EMPTY}
      disabledSteps={disabledSteps}
      saveButtonDisabled={disabledSteps.length > 0}
      reset={reset}
      save={createWorkload}
      onSuccess={onSuccess}
      onChangeCurrentStep={reset}
      documentationLink={DATA_PROCESSING_DEVELOPER_GUIDE}
    >
      {children}
    </SubmitApiRequestView>
  );
});

export default DataWorkloadWizard;

import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import { isEmptyString, noop } from "@util";
import Link from "@components/link";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { IconButton } from "@components";
import FavoriteIcon from "@material-ui/icons/StarBorder";
import FavoritedIcon from "@material-ui/icons/Star";
import ReleaseNotesIconButton from "@components/release-notes-icon-button";
import { ReleaseNotePlatformDomain } from "@data";

export interface Model {
  className?: string;
  title?: string;
  subheader?: boolean;
  links?: {
    className?: string,
    path: string,
    label: string,
    icon: React.ComponentType<SvgIconProps>,
  }[];
  favorites?: string[];
  favoriteModules?: string[];
  isUserPrincipal?: boolean;
  showDataAndAnalytics?: boolean;
  showDashboardsLink?: boolean;
  showDeviceEnrollmentLink?: boolean;
  showManagedPolicyLink?: boolean;
  showCertificateAuthorityLink?: boolean;
  showDevicesLink?: boolean;
  showDataLake?: boolean;
  showDataWorkloads?: boolean;
  showApplicationsRegionalModule?: boolean;
  showSecurityServicesRegionalModule?: boolean;
  releaseNotesPlatformDomain?: ReleaseNotePlatformDomain;
  releaseNotesClassName?: string;
  releaseNotesTooltip?: string;
}

export interface Actions {
  setFavorites?: (newFavorites: string[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const WelcomePageDomain = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    subheader,
    title = "",
    links = [],
    isUserPrincipal = false,
    favorites = [],
    releaseNotesPlatformDomain,
    releaseNotesClassName,
    releaseNotesTooltip,
    setFavorites = noop,
  } = props;

  const updateFavorites = React.useCallback((moduleId: string) => {
    const index = favorites.indexOf(moduleId);
    if (index > -1) { // already favorited, remove favorite
      setFavorites(favorites.filter(x => x !== moduleId));
    } else { // not favorited, add to favorites
      setFavorites(favorites.concat(moduleId));
    }
  }, [favorites, setFavorites]);

  const isFavorite = React.useCallback((moduleId: string) =>
    favorites.indexOf(moduleId) >= 0, [favorites]);

  const validLinks = React.useMemo(() =>
      links.filter(({ path, label, icon: LinkIcon = null }) =>
        !isEmptyString(path) && !isEmptyString(label) && (LinkIcon !== null)),
    [links]);

  if (isEmptyString(title) || links.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames("welcomePageDomain", className, classes.domain, {
        [classes.subheaderDomain]: subheader,
      })}
    >
      <div
        className={classnames("header", classes.header, {
          [classes.subheader]: subheader,
        })}
      >
        <label
          className={classnames("title", classes.title, {
            [classes.subheaderTitle]: subheader,
          })}
        >
          {title}
        </label>
        <ReleaseNotesIconButton
          className={classnames(releaseNotesClassName, classes.releaseNotesIconButton)}
          platformDomain={releaseNotesPlatformDomain}
          tooltip={releaseNotesTooltip}
        />
      </div>
      <div className={classnames("content", "links", classes.content, classes.links)}>
        {validLinks.map(({ className: linkClassName, path, label, icon: LinkIcon }) => (
          <div key={camelCase(label)} className={classnames("moduleItem", classes.moduleItem)}>
            <Link
              className={classnames("link", linkClassName, classes.link)}
              href={path}
            >
              <LinkIcon className={classnames("icon", classes.icon)} />
              <label className={classnames("label", classes.label)}>
                {label}
              </label>
            </Link>
            {isUserPrincipal && (
              <IconButton
                className={classnames("favoriteIcon", classes.favoriteButton)}
                onClick={() => updateFavorites(label ? label : "")}
              >
                {isFavorite(label ? label : "") ?
                  <FavoritedIcon className={classnames("favoritedIcon", classes.favoriteIcon)}/> :
                  <FavoriteIcon className={classnames("favoriteIcon", classes.favoriteIcon)}/>}
              </IconButton>
            )
            }
          </div>
        ))}
      </div>
    </div>
  );
});

export default WelcomePageDomain;

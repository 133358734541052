import { makeJsonApiRequest, withAuthToken, withRequiredArguments } from "@network/helpers";
import { isEmptyString } from "@util";

const UI_PERSONALIZATION_URL = process.env.REACT_APP_PORTAL_UI_PERSONALIZATION_API || "";

if (isEmptyString(UI_PERSONALIZATION_URL)) {
  throw new Error(
    "Missing Environment Variable: REACT_APP_PORTAL_UI_PERSONALIZATION_API"
  );
}

export interface GetUiPreferenceResponse {
  entityValue: Favorites;
}

export interface Favorites {
  modules: string[];
}

export const getPreference = (authToken: string, preferenceName: string): Promise<GetUiPreferenceResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Preference Name", preferenceName],
    ]));

  const makeRequest = () => {

    const url = `${UI_PERSONALIZATION_URL}/portal/settings/v1/preference/${preferenceName}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to get preference";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const updatePreference = (authToken: string, preferenceName: string, preferenceValue: string)
  : Promise<GetUiPreferenceResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Preference Name", preferenceName],
      ["Preference Value", preferenceValue],
    ]));

  const makeRequest = () => {

    const url = `${UI_PERSONALIZATION_URL}/portal/settings/v1/preference/${preferenceName}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: preferenceValue,
    };

    const defaultErrorMessage = "Failed to update preference";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

import React from "react";
import classnames from "classnames";
import { SearchFilter } from "@data";
import { isEmptyString, noop } from "@util";
import { PrimaryIcon } from "@modules/dataWorkloads/styles";
import WorkloadsListViewItem from "./WorkloadsListViewItem";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";
import useGetWorkloadsListViewItems from "./useGetWorkloadsListViewItems";
import ModuleListView, {
  createColumns,
  ListViewItem,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";

export enum WorkloadsListViewColumn {
  NAME = "Name",
  TYPE = "Type",
  VERSION = "Version",
  RELEASED_BY = "Released By",
}

export const DEFAULT_RELEASED_WORKLOADS_LIST_VIEW_COLUMNS: WorkloadsListViewColumn[] = [
  WorkloadsListViewColumn.NAME,
  WorkloadsListViewColumn.VERSION,
  WorkloadsListViewColumn.RELEASED_BY,
];

export const DEFAULT_SUMMARY_WORKLOADS_LIST_VIEW_COLUMNS: WorkloadsListViewColumn[] = [
  WorkloadsListViewColumn.NAME,
  WorkloadsListViewColumn.TYPE,
];

type Item = WorkloadsListViewItem;
type Column = WorkloadsListViewColumn;

export interface WorkloadsListViewModel extends ModuleListViewModel<Item, Column> {
  nameFilter?: string;
  releasedByNameFilter?: string;
  filterByReleasedState?: boolean;
  searchFilters?: SearchFilter[];
  workloads?: Item[];
  excludedWorkloads?: Item[];
  selectedWorkloads?: Item[];
}

export interface WorkloadsListViewActions extends ModuleListViewActions<Item, Column> {
  setSelectedWorkloads?: (selectedWorkloads: Item[]) => void;
  showWorkloadDetails?: (name: string) => void;
  onChangeShowLoadingIndicator?: (showLoadingIndicator?: boolean) => void;
}

type Model = WorkloadsListViewModel;
type Actions = WorkloadsListViewActions;
type Props = Model & Actions;

export const WorkloadsListView = ((props: Props) => {

  const {
    nameFilter,
    releasedByNameFilter,
    filterByReleasedState,
    searchFilters = [],
    workloads = [],
    excludedWorkloads = [],
    selectedWorkloads = [],
    selectedItems = selectedWorkloads,
    listClassName = React.useMemo(() =>
        filterByReleasedState ? "releasedWorkloadList" : "workloadsList",
      [filterByReleasedState]),
    noResultsLabel = React.useMemo(() =>
        filterByReleasedState ? "No released workloads found" : "No workloads found",
      [filterByReleasedState]),
    columns = React.useMemo(() => {
      if (filterByReleasedState) {
        return DEFAULT_RELEASED_WORKLOADS_LIST_VIEW_COLUMNS;
      } else {
        return DEFAULT_SUMMARY_WORKLOADS_LIST_VIEW_COLUMNS;
      }
    }, [filterByReleasedState]),
    summaryViewIcon = PrimaryIcon,
    showSummary = true,
    showSearch = true,
    createButtonClassName,
    createButtonLabel = "Create Workload",
    setSelectedWorkloads = noop,
    setSelectedItems = setSelectedWorkloads,
    showWorkloadDetails = noop,
    onClickItem = React.useCallback((item: Item) =>
        showWorkloadDetails(item.getName()),
      [showWorkloadDetails]),
    // TODO: Moving the filters menu into this component would remove the need for this prop
    onChangeShowLoadingIndicator = noop,
    ...otherProps
  } = props;

  const [{ workloads: items, showLoadingIndicator, ...model }, actions] = useGetWorkloadsListViewItems({
    nameFilter,
    releasedByNameFilter,
    filterByReleasedState,
    workloads,
  });

  const validItems = React.useMemo(() =>
    items.filter(item =>
      !excludedWorkloads.some(excludedItem => excludedItem.getName() === item.getName())),
    [items, excludedWorkloads]);

  const moduleListItems = React.useMemo<ListViewItem<WorkloadsListViewItem>[]>(() =>
    validItems.map((workload: WorkloadsListViewItem) => ({
      item: workload,
      icon: PrimaryIcon,
      pathToDetailsView: getPathToWorkload(workload.getName()),
      columnAttributes: createColumns([
        {
          className: "workloadName",
          value: workload.getName(),
          column: WorkloadsListViewColumn.NAME,
          firstColumn: true,
        },
        {
          className: "workloadType",
          value: workload.getTypeLabel(),
          column: WorkloadsListViewColumn.TYPE,
        },
        {
          className: "workloadVersion",
          value: workload.getVersionAsString(),
          column: WorkloadsListViewColumn.VERSION,
        },
        {
          className: "workloadReleasedBy",
          value: workload.getReleasedByName(),
          column: WorkloadsListViewColumn.RELEASED_BY,
        },
      ]),
    })), [validItems]);

  const searchVisible = React.useMemo(() => showSearch &&
      (validItems.length > 1 || !isEmptyString(nameFilter) || searchFilters.length > 0),
    [showSearch, validItems, nameFilter, searchFilters]);

  React.useEffect(() => {
    onChangeShowLoadingIndicator(showLoadingIndicator);
  }, [showLoadingIndicator, onChangeShowLoadingIndicator]);

  return (
    <ModuleListView
      {...otherProps}
      {...model}
      {...actions}
      showLoadingIndicator={showLoadingIndicator}
      showSummary={showSummary}
      showSearch={searchVisible}
      summaryViewIcon={summaryViewIcon}
      noResultsLabel={noResultsLabel}
      nameFilter={nameFilter}
      listClassName={listClassName}
      createButtonLabel={createButtonLabel}
      createButtonClassName={classnames("createWorkloadButton", createButtonClassName)}
      columns={columns}
      listViewItems={moduleListItems}
      items={validItems}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      onClickItem={onClickItem}
    />
  );
});

export default WorkloadsListView;

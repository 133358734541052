import React from "react";
import WorkloadSummary from "@data/WorkloadSummary";
import { ListWorkloadSummariesResponse } from "@network";
import useWorkloadManagerClient from "../use-workload-manager-client";
import usePaginatedApiRequest, {
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks/use-paginated-api-request";

type SuccessResponse = ListWorkloadSummariesResponse;

export interface UseGetWorkloadsProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  nameFilter?: string;
  workloads?: WorkloadSummary[];
}

export interface UseGetWorkloadsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  workloads: WorkloadSummary[];
}

export interface UseGetWorkloadsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseGetWorkloadsProps;
type Model = UseGetWorkloadsModel;
type Actions = UseGetWorkloadsActions;
type Result = [Model, Actions];

export const useGetWorkloads = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get workloads",
    nameFilter,
    workloads: initialWorkloads = [],
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const [workloads, setWorkloads] = React.useState<WorkloadSummary[]>(initialWorkloads);

  const makeApiRequest = React.useCallback((accessToken, next) => {
    return WorkloadManagerClient.getWorkloadsApi(nameFilter, next);
  }, [WorkloadManagerClient, nameFilter]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedWorkloads = React.useMemo<WorkloadSummary[]>(() => {
    const { items = [] } = successResponse ? successResponse : {};
    return items.map(attrs => new WorkloadSummary(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setWorkloads([]);
    baseReset();
  }, [setWorkloads, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  React.useEffect(() => {
    refresh();
  }, [
    refresh,
    nameFilter,
  ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    workloads,
  }), [
    baseModel,
    workloads,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setWorkloads(currentWorkloads => currentWorkloads.concat(updatedWorkloads));
  }, [setWorkloads, updatedWorkloads]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetWorkloads;

import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { useEditTags } from "@hooks";
import { TagEditor } from "@components";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface WorkloadTagsDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
  tags?: string[];
  dialogClassName?: string;
}

export interface WorkloadTagsDialogActions extends PortalModuleDialogActions<string> {
  setTags?: (tags: string[]) => void;
}

type Props = WithStyles<typeof styles> & WorkloadTagsDialogModel & WorkloadTagsDialogActions;

export const WorkloadTagsDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    name = "",
    version = 1,
    etag = "",
    tags = [],
    setTags = noop,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const [{ showLoadingIndicator, ...otherModel }, { editTags, reset }] =
    useEditTags({ name, version, etag, tags });

  const confirm = React.useCallback(() => {
    editTags();
  }, [editTags]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
  }, [reset, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  if (isEmptyString(name)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadTagsDialog", dialogClassName)}
      title={"Edit Workload Tags"}
      loading={showLoadingIndicator}
      continueButtonLabel={"Save"}
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadTagsContainer", classes.container)}>
        <label className={classnames("title", classes.title)}>
          Enter the new tags for this workload:
        </label>
        <TagEditor
          tags={tags}
          fullWidth={true}
          setTags={setTags}
          helperText="Tags to associate with this workload"
        />
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadTagsDialog;

import React from "react";
import classnames from "classnames";
import { isEmptyString, noop, Quantities } from "@util";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { ActionMenuItem } from "@components/actions-menu";
import { withQuantity } from "@components/summary";
import ContinueButton  from "@components/continue-button";
import PaginatedList, {
  ComparableItem,
  PaginatedListActions,
  PaginatedListModel,
} from "@components/paginated-list";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import RefreshButton from "@components/refresh-button";
import NameFilter from "@components/name-filter";
import styles from "./styles";

export interface SortedSearchResultsListModel<Item extends ComparableItem<Item>, Column extends string>
  extends PaginatedListModel<Item> {

  className?: string;
  listClassName?: string;
  items?: Item[];
  selectedItems?: Item[];
  columns?: Column[];
  sortByColumn?: Column;
  sortOrderAscending?: boolean;
  nameFilter?: string;
  nameFilterDelay?: number;
  errorMessage?: string;
  showLoadingIndicator?: boolean;
  loadingLabel?: string;
  showLoadMoreButton?: boolean;
  actions?: ActionMenuItem[];
  sortingDisabled?: boolean;
  selectable?: boolean;
  selectAllDisabled?: boolean;
  noResultsLabel?: string;
  noSearchResultsLabel?: string;
  loadMoreLabel?: string;
  showSummary?: boolean;
  showRefreshButton?: boolean;
  refreshButtonDisabled?: boolean;
  showIcon?: boolean;
  summaryViewClassName?: string;
  summaryViewLabel?: string;
  summaryViewQuantities?: Quantities;
  summaryViewIcon?: React.ComponentType<SvgIconProps>;
  summaryViewFiltersMenu?: React.ReactNode;
  showSearch?: boolean;
  searchViewHint?: string;
  tableLayoutFixed?: boolean;
  showCreateButton?: boolean;
  createButtonLabel?: string;
  createButtonClassName?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
}

export interface SortedSearchResultsListActions<Item extends ComparableItem<Item>, Column extends string>
  extends PaginatedListActions<Item> {

  loadMore?: () => void;
  refresh?: () => void;
  setSelectedItems?: (items: Item[]) => void;
  setNameFilter?: (nameFilter: string) => void;
  setSortByColumn?: (column: Column) => void;
  setSortOrderAscending?: (ascending: boolean) => void;
  onClickItem?: (item: Item) => void;
  onClickCreateButton?: (item: Item) => void;
  onClickAction?: (item: Item, action: ActionMenuItem) => void;
  mapItemToAction?: (item: Item, action: ActionMenuItem) => ActionMenuItem;
  renderItem?: (item: Item, column: Column) => React.ReactNode;
}

type Props<Item extends ComparableItem<Item>, Column extends string> =
  WithStyles<typeof styles> &
  SortedSearchResultsListModel<Item, Column> &
  SortedSearchResultsListActions<Item, Column>;

export const SortedSearchResultsList = withStyles(styles)(
  <Item extends ComparableItem<Item>, Column extends string>(props: Props<Item, Column>) => {

    const {
      classes,
      className,
      listClassName,
      items = [],
      selectedItems = [],
      columns = [],
      sortByColumn,
      sortOrderAscending = true,
      nameFilter = "",
      nameFilterDelay,
      errorMessage,
      showLoadingIndicator,
      loadingLabel,
      showLoadMoreButton,
      actions = [],
      sortingDisabled,
      selectable,
      selectAllDisabled,
      maxNumSelectedItems = 0,
      noResultsLabel: defaultNoResultsLabel,
      noSearchResultsLabel = "No results found",
      loadMoreLabel,
      showSummary,
      summaryViewClassName,
      summaryViewLabel,
      summaryViewQuantities = {
        other: "items",
        one: "item",
      },
      summaryViewIcon,
      summaryViewFiltersMenu,
      showSearch,
      searchViewHint,
      tableLayoutFixed = true,
      header,
      showRefreshButton = true,
      refreshButtonDisabled,
      showIcon = true,
      showCreateButton = false,
      createButtonLabel = "Create",
      createButtonClassName = "create",
      children,
      loadMore = noop,
      onClickLoadMore = loadMore,
      refresh = noop,
      setSelectedItems = noop,
      setNameFilter = noop,
      setSortByColumn = noop,
      setSortOrderAscending = noop,
      onClickItem = noop,
      onClickCreateButton = noop,
      onClickAction = noop,
      mapItemToAction,
      renderItem,
      ...otherProps
    } = props;

    const showOnlySelectedItems = React.useMemo(() =>
        selectable && maxNumSelectedItems > 0 && selectedItems.length >= maxNumSelectedItems,
      [selectable, maxNumSelectedItems, selectedItems]);

    const SummaryView = React.useMemo(() =>
      withQuantity(summaryViewQuantities), [summaryViewQuantities]);

    const onItemSelected = React.useCallback((item: Item) => {

      const checked = selectedItems.some((selectedItem: Item) => item.equals(selectedItem));

      if (checked) {
        return setSelectedItems(selectedItems.filter(selectedItem => !item.equals(selectedItem)));
      } else {
        return setSelectedItems(selectedItems.concat(item));
      }

    }, [selectedItems, setSelectedItems]);

    const onItemClicked = React.useMemo(() =>
        selectable ? onItemSelected : onClickItem,
      [selectable, onItemSelected, onClickItem]);

    const onClickToggleSortOrder = React.useCallback(() =>
      setSortOrderAscending(!sortOrderAscending), [sortOrderAscending]);

    const numResults = React.useMemo(() => items.length, [items]);

    const sortButtonDisabled = React.useMemo(() =>
      sortingDisabled || (numResults < 1),
      [sortingDisabled, numResults]);

    const showErrorView = React.useMemo(() =>
      !isEmptyString(errorMessage), [errorMessage]);

    const showLoadingView = React.useMemo(() =>
      showLoadingIndicator && !showErrorView,
      [showLoadingIndicator, showErrorView]);

    const showNoResultsView = React.useMemo(() =>
      numResults === 0 && !showLoadingIndicator && !showErrorView,
      [numResults, showLoadingIndicator, showErrorView]);

    const isLoadMoreButtonVisible = React.useMemo(() =>
      showLoadMoreButton && !showLoadingIndicator && !showErrorView,
      [showLoadMoreButton, showLoadingIndicator, showErrorView]);

    const refreshButton = React.useMemo(() => !showRefreshButton ? null : (
      <RefreshButton
        className={classnames("refresh", classes.refreshButton)}
        loading={showLoadingIndicator}
        refresh={refresh}
        disabled={refreshButtonDisabled}
      />
    ), [classes, showLoadingIndicator, refresh, showRefreshButton, refreshButtonDisabled]);

    const createButton = React.useMemo(() => !showCreateButton ? null : (
      <ContinueButton
        className={classnames("create", classes.createButton, createButtonClassName)}
        label={createButtonLabel}
        onClick={onClickCreateButton}
      />
    ), [classes, createButtonLabel, onClickCreateButton, showCreateButton]);

    const summaryView = React.useMemo(() => !showSummary ? null : (
      <SummaryView
        className={summaryViewClassName}
        quantity={numResults}
        label={summaryViewLabel}
        icon={summaryViewIcon}
        showIcon={showIcon}
      />
    ), [
      showSummary,
      SummaryView,
      summaryViewClassName,
      numResults,
      summaryViewLabel,
      summaryViewIcon,
      showIcon,
    ]);

    const summaryViewContainer = React.useMemo(() => !showSummary ? null : (
      <div className={classnames("summaryViewContainer", classes.summaryViewContainer)}>
        {summaryView}
        {refreshButton}
        {summaryViewFiltersMenu}
        <div className={classnames("controls", classes.controls)}>
          {createButton}
          {children}
        </div>
      </div>
    ), [
      showSummary,
      classes,
      summaryView,
      refreshButton,
      summaryViewFiltersMenu,
      createButton,
      children,
    ]);

    const searchView = React.useMemo(() => showOnlySelectedItems || !showSearch ? null : (
      <NameFilter
        className={classnames("search", classes.search)}
        hint={searchViewHint}
        nameFilter={nameFilter}
        delay={nameFilterDelay}
        setNameFilter={setNameFilter}
      />
    ), [
      showOnlySelectedItems,
      showSearch,
      classes,
      searchViewHint,
      nameFilter,
      nameFilterDelay,
      setNameFilter,
    ]);

    const noResultsLabel = React.useMemo(() => {
      if (isEmptyString(nameFilter)) {
        return defaultNoResultsLabel;
      } else {
        return noSearchResultsLabel;
      }
    }, [nameFilter, defaultNoResultsLabel, noSearchResultsLabel]);

    const onClickColumn = React.useCallback(
      (column: Column) => setSortByColumn(column),
      [setSortByColumn]);

    return (
      <div className={classnames(className, classes.container)}>
        {summaryViewContainer}
        {searchView}
        {header}
        <PaginatedList
          {...otherProps}
          className={listClassName}
          columns={columns}
          sortedColumn={sortByColumn}
          actions={actions}
          items={items}
          error={errorMessage}
          sortButtonDisabled={sortButtonDisabled}
          sortOrderAscending={sortOrderAscending}
          selectable={selectable}
          selectAllDisabled={selectAllDisabled}
          selectedItems={selectedItems}
          maxNumSelectedItems={maxNumSelectedItems}
          noResultsLabel={noResultsLabel}
          loadMoreLabel={loadMoreLabel}
          tableLayoutFixed={tableLayoutFixed}
          showErrorView={showErrorView}
          showLoadingView={showLoadingView}
          loadingLabel={loadingLabel}
          showNoResultsView={showNoResultsView}
          showLoadMoreButton={isLoadMoreButtonVisible}
          onClickLoadMore={onClickLoadMore}
          onClickItem={onItemClicked}
          onClickAction={onClickAction}
          onClickColumn={onClickColumn}
          onClickToggleSortOrder={onClickToggleSortOrder}
          setSelectedItems={setSelectedItems}
          mapItemToAction={mapItemToAction}
          renderItem={renderItem}
        />
      </div>
    );
  });

export default SortedSearchResultsList;

import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue, isEmptyString } from "@util";
import { DEFAULT_WORKLOAD_TYPE_LABELS, WorkloadType } from "@data/Workload";
import ReleasedWorkloadIdentity from "@data/ReleasedWorkloadIdentity";
import WorkloadSummary from "@data/WorkloadSummary";

export interface WorkloadsListViewItemAttributes {
  name: string;
  type: WorkloadType;
  releasedByName: string;
  version: number;
}

export class WorkloadsListViewItem extends Record({
  name: "",
  type: WorkloadType.NONE,
  releasedByName: "",
  version: -1,
}) implements WorkloadsListViewItemAttributes {

  public static EMPTY: WorkloadsListViewItem = new WorkloadsListViewItem();

  public readonly name: string;
  public readonly type: WorkloadType;
  public readonly releasedByName: string;
  public readonly version: number;

  public static fromReleasedWorkloadIdentity(workload: ReleasedWorkloadIdentity): WorkloadsListViewItem {
    return new WorkloadsListViewItem({
      name: workload.getName(),
      releasedByName: workload.getReleasedByName(),
      version: workload.getVersion(),
    });
  }

  public static fromWorkloadSummary(workload: WorkloadSummary): WorkloadsListViewItem {
    return new WorkloadsListViewItem({
      name: workload.getName(),
      type: workload.getType(),
    });
  }

  // Regardless of whether the workloads list is showing released workloads or summary workloads,
  // the list is guaranteed to only ever contain the same workload name once.
  public equals(other: WorkloadsListViewItem): boolean {
    return equalsIgnoreCase(this.getName(), other.getName());
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getType(): WorkloadType {
    return this.type;
  }

  public hasType(): boolean {
    return this.getType() !== WorkloadType.NONE;
  }

  public getTypeLabel(): string {
    return DEFAULT_WORKLOAD_TYPE_LABELS[this.getType()];
  }

  public getReleasedByName(): string {
    return getStringValue(this.releasedByName);
  }

  public getVersion(): number {
    return this.version;
  }

  public getVersionAsString(): string {
    const version = this.getVersion();
    return version >= 0 ? `${version}` : "";
  }

  public isReleased(): boolean {
    return !isEmptyString(this.getReleasedByName());
  }

  public toReleasedWorkloadIdentity(): ReleasedWorkloadIdentity {
    return new ReleasedWorkloadIdentity({
      name: this.getName(),
      releasedByName: this.getReleasedByName(),
      version: this.getVersion(),
    });
  }

  public toWorkloadSummary(): WorkloadSummary {
    return new WorkloadSummary({
      name: this.getName(),
      type: this.getType(),
    });
  }
}

export default WorkloadsListViewItem;

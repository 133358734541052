import React from "react";
import classnames from "classnames";
import CloseIcon from "@material-ui/icons/Help";
import OpenIcon from "@material-ui/icons/HelpOutline";
import { IconButton } from "@components/icon-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { BuildInfoLabel } from "./BuildInfoLabel";
import { clickHandler } from "@util";
import styles from "./styles";

export enum BuildInfoAnchor {
  TOP_LEFT = "topLeft",
  TOP_RIGHT = "topRight",
  BOTTOM_LEFT = "bottomLeft",
  BOTTOM_RIGHT = "bottomRight",
}

export interface BuildInfoProps {
  anchor?: BuildInfoAnchor;
  whitelistedAccounts?: string;
  federationAppId?: string;
  schemaRegistryApi?: string;
  deviceTypeApi?: string;
  namespaceApi?: string;
  portalApiUrl?: string;
  version?: string;
  versionCode?: string;
  environment?: string;
}

type Props = WithStyles<typeof styles> & BuildInfoProps & {
  children?: React.ReactNode;
};

export const BuildInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    anchor = BuildInfoAnchor.TOP_LEFT,
    whitelistedAccounts = process.env.REACT_APP_WHITELISTED_ACCOUNTS,
    federationAppId = process.env.REACT_APP_FEDERATION_APP_ID,
    schemaRegistryApi = process.env.REACT_APP_SCHEMA_REGISTRY_API,
    deviceTypeApi = process.env.REACT_APP_DEVICE_TYPE_V3_API,
    namespaceApi = process.env.REACT_APP_NAMESPACE_API,
    version = process.env.REACT_APP_VERSION,
    versionCode = process.env.REACT_APP_VERSION_CODE,
    environment = process.env.REACT_APP_CONFIG_ENV,
    children,
  } = props;

  const [open, setOpen] = React.useState(false);

  const ShowBuildInfoButton = () => (
    <IconButton
      color="inherit"
      disableRipple={true}
      className={classes.iconButton}
      onClick={clickHandler(() => setOpen(!open))}
    >
      {open && (<CloseIcon className={classes.icon} />)}
      {!open && (<OpenIcon className={classes.icon} />)}
    </IconButton>
  );

  const showButtonAtTop = anchor === BuildInfoAnchor.TOP_LEFT ||
    anchor === BuildInfoAnchor.TOP_RIGHT;

  const showButtonAtBottom = anchor === BuildInfoAnchor.BOTTOM_LEFT ||
    anchor === BuildInfoAnchor.BOTTOM_RIGHT;

  return (
    <div className={classnames("buildInfoContainer", classes[anchor], classes.container)}>
      {showButtonAtTop && <ShowBuildInfoButton />}
      {open && (
        <div className={classes.content}>
          <BuildInfoLabel
            className="whitelistedAccounts"
            name="WHITELISTED_ACCOUNTS"
            value={whitelistedAccounts}
          />
          <BuildInfoLabel
            className="federationAppId"
            name="FEDERATION_APP_ID"
            value={federationAppId}
          />
          <BuildInfoLabel
            className="schemaRegistryApi"
            name="SCHEMA_REGISTRY_API"
            value={schemaRegistryApi}
          />
          <BuildInfoLabel
            className="deviceTypeApi"
            name="DEVICE_TYPE_V3_API"
            value={deviceTypeApi}
          />
          <BuildInfoLabel
            className="namespaceApi"
            name="NAMESPACE_API"
            value={namespaceApi}
          />
          <BuildInfoLabel
            className="version"
            name="VERSION_CODE"
            value={version}
          />
          <BuildInfoLabel
            className="versionCode"
            name="VERSION_CODE"
            value={versionCode}
          />
          <BuildInfoLabel
            className="environment"
            name="ENVIRONMENT"
            value={environment}
          />
        </div>
      )}
      {showButtonAtBottom && <ShowBuildInfoButton />}
      {children}
    </div>
  );
});

export default BuildInfo;

import React from "react";
import { Model, Actions, InfoView } from "../components/InfoView";
import { ValidateWorkloadNameResponse } from "@network";
import { useValidateWorkloadName } from "@hooks";
import { noop } from "@util";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const InfoViewContainer = (props: Props) => {

  const {
    setIsValidName = noop,
  } = props;

  const onSuccess = React.useCallback((response: ValidateWorkloadNameResponse) => {
    setIsValidName(response.isValid);
  }, [setIsValidName]);

  const [pendingName, setPendingName] = React.useState("");

  const [{ message, showLoadingIndicator: validateLoading }, { refresh: validateName }] =
    useValidateWorkloadName({name: pendingName, onSuccess});

  const onChangeName = React.useCallback((name: string) => {
    setPendingName(name);
  }, [setPendingName]);

  return (
    <InfoView
      {...props}
      pendingName={pendingName}
      validateLoading={validateLoading}
      message={message}
      validateName={validateName}
      setPendingName={setPendingName}
      onChangeName={onChangeName}
    />
  );
};

export default InfoViewContainer;
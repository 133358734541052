import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import TextField from "@components/text-field";
import { useEditDescription } from "@hooks";
import { formEventHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface WorkloadDescriptionDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
  description?: string;
  dialogClassName?: string;
}

export interface WorkloadDescriptionDialogActions extends PortalModuleDialogActions<string> {
  setDescription?: (description: string) => void;
}

type Props = WithStyles<typeof styles> & WorkloadDescriptionDialogModel & WorkloadDescriptionDialogActions;

export const WorkloadDescriptionDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    name = "",
    version = 1,
    etag = "",
    description = "",
    setDescription = noop,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  if (isEmptyString(name)) {
    return null;
  }

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const [{ showLoadingIndicator, ...otherModel }, { editDescription, reset }] =
    useEditDescription({ name, version, etag, description });

  const confirm = React.useCallback(() => {
    editDescription();
  }, [editDescription]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
  }, [reset, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadDescriptionDialog", dialogClassName)}
      title={"Edit Workload Description"}
      loading={showLoadingIndicator}
      continueButtonLabel={"Save"}
      continueButtonDisabled={isEmptyString(description)}
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadDescriptionContainer", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Enter the new description for this workload:
          </label>
          <div className={classnames("workloadDescription", classes.descriptionInput)}>
            <TextField
              className={classnames("description", classes.textField)}
              variant="outlined"
              autoComplete="off"
              margin="none"
              fullWidth={true}
              autoFocus={true}
              required={true}
              multiline={true}
              minRows={3}
              label="Description"
              name="description"
              value={description}
              placeholder="Description"
              onChange={formEventHandler((updatedDescription: string) => setDescription(updatedDescription))}
              InputLabelProps={inputLabelProps}
            />
          </div>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadDescriptionDialog;

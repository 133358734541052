import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";
import { getCreateWorkloadPath } from "@modules/dataWorkloadWizard/helpers";
import WorkloadsList from "@components/workloads-list";

export function DataWorkloadView() {

  const history = useHistory();

  const createWorkload = React.useCallback(() =>
    history.push(getCreateWorkloadPath()), [history]);

  const showWorkloadDetails = React.useCallback((workloadName: string) =>
    history.push(getPathToWorkload(workloadName)), [history]);

  return (
    <WorkloadsList
      showCreateButton={true}
      createWorkload={createWorkload}
      showWorkloadDetails={showWorkloadDetails}
    />
  );
}

export default DataWorkloadView;

import { Record } from "immutable";
import { getStringValue, isEmptyString, isValidNumber } from "@util";
import { TriggerAttributes, TriggerType } from "@data";

export type TriggerStorage = {
  bucketName: string;
  key: string;
  size?: number;
};

export enum ExecutionScope {
  NONE = "",
  WORKLOAD = "WORKLOAD",
  WORKFLOW = "WORKFLOW",
}

export class ExecuteWorkloadEvent extends Record({
  eventType: TriggerType.NONE,
  "@type": TriggerType.NONE,
  type: TriggerType.NONE,
  eventDatetime: "",
  scheduleId: "",
  dataSetAlias: "",
  triggerStorage: ({} as TriggerStorage),
  precedingWorkloadName: "",
  accountId: "",
  listFile: [],
  executionScope: ExecutionScope.NONE,
}) implements TriggerAttributes {

  public static EMPTY: ExecuteWorkloadEvent = new ExecuteWorkloadEvent();

  public readonly eventType: TriggerType;
  public readonly "@type": TriggerType;
  public readonly type: TriggerType;
  public readonly eventDatetime: string;
  public readonly scheduleId: string;
  public readonly triggerStorage: TriggerStorage;
  public readonly precedingWorkloadName: string;
  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly listFile: string[];
  public readonly executionScope: ExecutionScope;

  public static fromTrigger(trigger: TriggerAttributes): ExecuteWorkloadEvent {
    return new ExecuteWorkloadEvent({
      ...trigger,
      type: trigger.type,
      scheduleId: trigger.scheduleId,
      precedingWorkloadName: trigger.precedingWorkloadName,
      dataSetAlias: trigger.dataSetAlias,
    });
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getTriggerStorage(): TriggerStorage {
    return this.triggerStorage || {};
  }

  public getBucketName(): string {
    const { bucketName = "" } = this.getTriggerStorage();
    return getStringValue(bucketName);
  }

  public getKey(): string {
    const { key = "" } = this.getTriggerStorage();
    return getStringValue(key);
  }

  public getSize(): number {
    const { size = 0 } = this.getTriggerStorage();
    return isValidNumber(size) ? size : 0;
  }

  public getType(): TriggerType {
    return this.type;
  }

  public getPrecedingWorkloadName(): string {
    return getStringValue(this.precedingWorkloadName);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getListFiles(): string[] {
    const listFile = Array.isArray(this.listFile) ? this.listFile : [];
    return listFile.filter(it => !isEmptyString(it));
  }

  public getScheduleId(): string {
    return getStringValue(this.scheduleId);
  }

  public getExecutionScope(): ExecutionScope {
    return this.executionScope;
  }
}

export default ExecuteWorkloadEvent;

import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useCurrentAccountId from "@hooks/use-current-account-id";
import DataWorkloadWizard from "./containers/DataWorkloadWizard";
import {
  CreateWorkloadRequest,
  CreateWorkloadRequestAttributes,
  WorkloadPythonCodeProperty,
  WorkloadPythonCodePropertyAttributes,
} from "@data";
import { getPathToWorkload } from "@modules/dataWorkloadDetails/helpers";
import DataSetsListItemData from "@components/data-sets-list/models/DataSetsListItemData";

export interface CodeInfo {
  sourceName: string;
  sourceVersion: number;
  containsCode: boolean;
}

type LocationProps = {
  code?: CodeInfo;
  data?: CreateWorkloadRequestAttributes;
};

export function CreateWorkloadWizardView() {

  const history = useHistory();

  const [ codeProperties, setCodeProperties ] =
    React.useState<WorkloadPythonCodeProperty>(WorkloadPythonCodeProperty.EMPTY);

  const setCodePropertiesData = React.useCallback((props: Partial<WorkloadPythonCodePropertyAttributes>) => {
    setCodeProperties(new WorkloadPythonCodeProperty({
      ...codeProperties.toJS(),
      ...props,
    }));
  }, [codeProperties, setCodeProperties]);

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showWorkloadDetails = React.useCallback(id => {
    history.replace(getPathToWorkload(id));
  }, [history]);

  const showDataSetDetails = React.useCallback(
    (item: DataSetsListItemData) => history.push(item.getHref()), [history]);

  const showDataSetDetailsInNewTab = React.useCallback(
    (item: DataSetsListItemData) => item.getHref(), []);

  const {
    state: {
      code: { sourceName, sourceVersion, containsCode } = { sourceName: "", sourceVersion: 1, containsCode: false },
      data: workloadRequestAttributes = CreateWorkloadRequest.EMPTY.toJS(),
    } = {},
  } = useLocation<LocationProps>();

  const accountId = useCurrentAccountId();
  const [initialized, setInitialized] = React.useState(false);
  const [data, setData] = React.useState<CreateWorkloadRequest>(new CreateWorkloadRequest({ accountId }));

  const workloadRequest = React.useMemo<null | CreateWorkloadRequest>(() =>
      typeof workloadRequestAttributes !== "object"
        ? null
        : new CreateWorkloadRequest({ accountId, ...workloadRequestAttributes }),
    [workloadRequestAttributes]);

  const isCloneFlowActive = React.useMemo(() =>
      workloadRequest !== null && !CreateWorkloadRequest.EMPTY.equals(workloadRequest),
    [workloadRequest]);

  React.useEffect(() => {

    if (!initialized) {

      if (isCloneFlowActive && workloadRequest !== null) {
        setData(workloadRequest);
      }

      setInitialized(true);
    }

  }, [initialized, isCloneFlowActive, workloadRequest, setInitialized]);

  if (!initialized) {
    return null;
  }

  return (
    <DataWorkloadWizard
      data={data}
      codeProperties={codeProperties}
      cancel={cancel}
      containsCode={containsCode}
      sourceName={sourceName}
      sourceVersion={sourceVersion}
      showDataSetDetails={showDataSetDetails}
      showWorkloadDetails={showWorkloadDetails}
      showDataSetDetailsInNewTab={showDataSetDetailsInNewTab}
      setCodeProperties={setCodePropertiesData}
    />
  );
}

export default CreateWorkloadWizardView;

import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { getUserId } from "@modules/userDetails/selectors";
import { PrimaryIcon as WorkloadIcon } from "@modules/dataWorkloads/styles";
import WorkloadsList, {
  WorkloadsListActions,
  WorkloadsListModel,
} from "@components/workloads-list";

interface WorkloadsModel extends WorkloadsListModel {
  hidden?: boolean;
  userId?: string;
}

interface WorkloadActions extends WorkloadsListActions {
}

type Props = WorkloadsModel & WorkloadActions;

const WorkloadsView = (props: Props) => {

  const {
    hidden,
    userId,
    showWorkloadDetails = noop,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState("");

  if (hidden) {
    return null;
  }

  return (
    <WorkloadsList
      {...otherProps}
      className="workloadsView"
      loadingLabel="Fetching workloads released by this user..."
      noResultsLabel="No workloads released by this user"
      summaryViewLabel="Workloads released by this user"
      summaryViewIcon={WorkloadIcon}
      showSummary={true}
      showSummaryViewFiltersMenu={false}
      nameFilter={nameFilter}
      filterByReleasedState={true}
      releasedByNameFilter={userId}
      setNameFilter={setNameFilter}
      showWorkloadDetails={showWorkloadDetails}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: WorkloadsModel): WorkloadsModel => ({
  userId: getUserId(state),
  ...ownProps,
});

const mapDispatchToProps = (_dispatch: any, ownProps: WorkloadActions): WorkloadActions => ({
  ...ownProps,
});

export default connect<WorkloadsModel, WorkloadActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(WorkloadsView);

import React from "react";
import { connect, useDispatch } from "react-redux";
import { AppSchema } from "@schemas";
import WelcomePageDomain, { Actions, Model } from "../components/WelcomePageDomain";
import {
  getFavoriteModules,
  getUserFavorites,
  isUserPrincipal
} from "@main/selectors";
import { useUpdateFavorites } from "@hooks/uiPersonalization/use-update-favorites";
import { isUiPersonalizationEnabled, noop, openBroadcastChannel } from "@util";
import { updateCurrentFavorites } from "@main/actions";
import { BROADCAST_CHANNEL_UPDATE_FAVORITES } from "@store/constants";

type Props = Model & Actions;

const WelcomePageDomainContainer = (props: Props) => {
  const {
    isUserPrincipal: isUser,
    favorites = [],
    favoriteModules = [],
    ...otherProps
  } = props;

  const [currFavorites, setCurrFavorites] = React.useState(favorites);

  const refresh = useUpdateFavorites({ newFavorites: currFavorites });

  const dispatch = useDispatch();

  const setFavorites = React.useCallback((newFavorites: string[]) => {
    setCurrFavorites(newFavorites);
    if (isUiPersonalizationEnabled()) {
      refresh();
    } else {
      dispatch(updateCurrentFavorites(newFavorites));
    }
  }, [setCurrFavorites, refresh]);

  React.useEffect(() => {
    if (isUiPersonalizationEnabled()) {
      setCurrFavorites(favoriteModules);
    } else {
      setCurrFavorites(favorites);
    }
  }, [favorites, favoriteModules]);

  React.useEffect(() => {
    if (isUiPersonalizationEnabled()) {
      setCurrFavorites(favoriteModules);
    } else {
      setCurrFavorites(favorites);
    }
  }, []);

  React.useEffect(() => {
    if (!isUiPersonalizationEnabled() || !isUser) {
      return noop;
    }
    // listen for changes to favorites from other tabs
    const listener = (broadcastEvent: MessageEvent) => {
      if (broadcastEvent.data.favorites) {
        setCurrFavorites(broadcastEvent.data.favorites);
      }
    };

    const { unsubscribe } = openBroadcastChannel(BROADCAST_CHANNEL_UPDATE_FAVORITES, listener);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <WelcomePageDomain
      {...otherProps}
      isUserPrincipal={isUser}
      setFavorites={setFavorites}
      favorites={currFavorites}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  isUserPrincipal: isUserPrincipal(state),
  favorites: getUserFavorites(state),
  favoriteModules: getFavoriteModules(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  ...ownProps,
});

export default connect<Model, Actions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomePageDomainContainer);

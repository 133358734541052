import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import { useDropzone } from "react-dropzone";
import { codeInfoView as styles } from "./styles";
import UploadIcon from "@material-ui/icons/Publish";
import DownloadIcon from "@material-ui/icons/GetApp";
import SaveIcon from "@material-ui/icons/Save";
import TestIcon from "@material-ui/icons/Code";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  AlertSeverity,
  DownloadButton,
  ErrorView,
  MissingAlertView,
  SaveButton,
  SummaryViewActionItems,
  SummaryViewModel,
  SummaryViewWithActions as WorkloadCodeSummaryView,
} from "@components";
import { LoadingIndicator } from "@components";
import WorkloadCodeEditor from "../components/WorkloadCodeEditor/WorkloadCodeEditor";

export interface Model extends SummaryViewModel {
  summaryViewItems?: SummaryViewActionItems[];
  codeUploaded?: boolean;
  fileDownloadLoading?: boolean;
  href?: string;
  workloadName?: string;
  version?: number;
  pythonWorkload?: boolean;
  file?: File;
  zipFile?: File;
  fileUploadSuccess?: boolean;
  fileUploadErrorMessage?: string;
  fileName?: string;
  fileType?: string;
  downloadErrorMessage?: string;
  fileUploadLoading?: boolean;
  fileSelected?: boolean;
  codeUploadDisabled?: boolean;
  statusCode?: number;
  codePackageSuccess?: boolean;
  showDownloadErrorView?: boolean;
  children?: React.ReactNode;
}

export interface Actions {
  uploadCode?: () => void;
  openUploadDialog?: () => void;
  downloadCode?: () => void;
  onSuccessUploadPyCode?: () => void;
  onSelectFile?: (event: WindowEventHandlers) => void;
  onDrop?: (files: File[]) => void;
  setFile?: (file: File) => void;
  enableSaveCodeRequest?: () => void;
  disableSaveCodeRequest?: () => void;
  testCode?: () => void;
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const CodeInfoView = withStyles(styles)((props: Props) => {

  const {
    classes,
    fileUploadSuccess = false,
    fileUploadLoading = false,
    showDownloadErrorView = false,
    downloadErrorMessage = "",
    codeUploaded,
    fileDownloadLoading,
    fileSelected = false,
    href,
    workloadName = "",
    version = 1,
    zipFile,
    fileName,
    fileType = "jar",
    summaryViewItems,
    fileUploadErrorMessage,
    codeUploadDisabled,
    codePackageSuccess,
    statusCode,
    pythonWorkload = false,
    uploadCode = noop,
    downloadCode = noop,
    onSelectFile = noop,
    onDrop = noop,
    onSuccessUploadPyCode = noop,
    enableSaveCodeRequest = noop,
    disableSaveCodeRequest = noop,
    setFile = noop,
    refresh = noop,
    testCode,
    children,
  } = props;

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    preventDropOnDocument: true,
  });

  const downloadLabelEl = React.useMemo<React.ReactNode>(() => (
    <React.Fragment>
      <DownloadIcon className={classnames("downloadButtonIcon", classes.buttonIcon)}/>
       Download Code
    </React.Fragment>
  ), []);

  const uploadLabelEl = React.useMemo<React.ReactNode>(() => (
    <React.Fragment>
      <UploadIcon className={classnames("uploadButtonIcon", classes.buttonIcon)}/>
      Upload Code
    </React.Fragment>
  ), []);

  const saveLabelEl = React.useMemo<React.ReactNode>(() => (
    <React.Fragment>
      <SaveIcon className={classnames("saveButtonIcon", classes.buttonIcon)}/>
      Save Code
    </React.Fragment>
  ), []);

  const testLabelEl = React.useMemo<React.ReactNode>(() => (
    <React.Fragment>
      <TestIcon className={classnames("testButtonIcon", classes.buttonIcon)}/>
      Test Code
    </React.Fragment>
  ), []);

  const noCodeWarning = React.useMemo(() =>
      !pythonWorkload ? "You have to upload code to view code related information and release/test this workload"
        : "You have to upload code to view and edit it"
    , [pythonWorkload]);

  const bannerText = React.useMemo(() =>
    !pythonWorkload ? "To upload code, click below or drag and drop your file (.jar file only)"
      : "Click upload button to upload code (.zip file only)"
    , [pythonWorkload]);

  const uploadButtonDisabled = React.useMemo(() =>
    !fileSelected || fileUploadSuccess || !isEmptyString(fileUploadErrorMessage) || codeUploadDisabled,
    [fileSelected, fileUploadSuccess, fileUploadErrorMessage, codeUploadDisabled]);

  const downloadButton = React.useMemo(() => (
    <DownloadButton
      className={classnames("download")}
      buttonClassName={classnames("downloadCodeButton", classes.downloadCodeButton)}
      downloadFileClassName="jarFile"
      fileName={`${workloadName}.${fileType}`}
      loading={fileDownloadLoading}
      disabled={!codeUploaded}
      color="primary"
      label={downloadLabelEl}
      downloadIndicatorSize={50}
      downloadFinishedDelay={0}
      href={href}
      download={downloadCode}
    />
  ), [
    classes,
    workloadName,
    fileType,
    fileDownloadLoading,
    codeUploaded,
    downloadLabelEl,
    href,
    downloadCode,
  ]);

  return (
    <div className={classnames("codeInfoView", classes.container)}>
      {!codeUploaded && (
        <MissingAlertView
          className={classnames("codeNotUploadedAlert", classes.missingAlert)}
          severity={AlertSeverity.WARNING}
          message={(
            <React.Fragment>
              <Typography variant="h6">
                {noCodeWarning}
              </Typography>
              <Typography className={classes.missingAlertBody} variant="body1">
                {bannerText}
              </Typography>
            </React.Fragment>
          )}
        />
      )}
        <WorkloadCodeSummaryView
          items={summaryViewItems}
        />
        {!pythonWorkload && (
          <React.Fragment>
            <div className={classnames("controls", classes.controls)}>
              <SaveButton
                className={classnames("uploadCodeButton", classes.uploadCodeButton)}
                color={"primary"}
                label={uploadLabelEl}
                loading={fileUploadLoading}
                downloadIndicatorSize={50}
                disabled={uploadButtonDisabled}
                save={uploadCode}
              />
              {downloadButton}
            </div>
            <form
              {...getRootProps()}
              className={classnames("uploadContent", classes.content, {
                [classes.contentDragging]: !fileUploadSuccess && isDragActive,
                [classes.contentSelected]: fileUploadSuccess || (fileSelected && !isDragActive),
                [classes.contentDisabled]: codeUploadDisabled,
              })}
            >
            {codeUploadDisabled && (
              <div className={classes.disabledLabel}>
                Code can only be uploaded/edited when workload is in DRAFT/DEPLOYMENT FAILED state
              </div>
            )}
            {fileUploadSuccess && !codeUploadDisabled && (
              <div className={classes.label}>
                Code uploaded successfully!
                <div>Click here to upload a different file</div>
              </div>
            )}
            {!fileUploadSuccess && !codeUploadDisabled && (
              <React.Fragment>
                {!fileSelected && isEmptyString(fileUploadErrorMessage) && (
                  <div className={classnames(classes.label, classes.uploadButton)}>
                    {codeUploaded && (
                      <div className={classnames(classes.label, classes.uploadButton)}>
                        Code already uploaded for this workload!
                        <div className={classnames(classes.subLabel)}>Click here to upload a different file</div>
                      </div>
                    )}
                    {!codeUploaded && (
                      <div className={classnames(classes.label, classes.uploadButton)}>
                        Click here to select a file to upload
                      </div>
                    )}
                  </div>
                )}
                {fileSelected && isEmptyString(fileUploadErrorMessage) && (
                  <div className={classnames(classes.label, classes.uploadButton)}>
                    File Name: {fileName}.
                    <div className={classnames(classes.subLabel)}>Click Upload Code button to continue.</div>
                    <div className={classnames(classes.subLabel)}>Click here to select a different file</div>
                  </div>
                )}
                {!isEmptyString(fileUploadErrorMessage) && (
                  <div>
                    <ErrorView title={"Error uploading file"} message={fileUploadErrorMessage} statusCode={statusCode}/>
                    <div className={classnames(classes.subLabel)}>Click here to try again or select a different file
                    </div>
                  </div>
                )}
                {fileSelected && fileUploadLoading && (
                  <LoadingIndicator
                    className={"loadingIndicator"}
                    label="Uploading..."
                    labelClassName={classnames("uploading", classes.dialogLabel)}
                  />
                )}
              </React.Fragment>
            )}
              <input {...getInputProps()} type="file" onChange={onSelectFile} disabled={codeUploadDisabled}/>
            </form>
        </React.Fragment>
      )}
      {pythonWorkload && (
        <WorkloadCodeEditor
          workloadName={workloadName}
          version={version}
          zipFile={zipFile}
          saveButtonLabel={saveLabelEl}
          uploadButtonLabel={uploadLabelEl}
          testButtonLabel={testLabelEl}
          codeDownloadLoading={fileDownloadLoading}
          codeUploadLoading={fileUploadLoading}
          codeUploadDisabled={codeUploadDisabled}
          fileUploadSuccess={fileUploadSuccess}
          codePackageSuccess={codePackageSuccess}
          showDownloadErrorView={showDownloadErrorView}
          downloadErrorMessage={downloadErrorMessage}
          fileUploadErrorMessage={fileUploadErrorMessage}
          statusCode={statusCode}
          onSuccessUploadPyCode={onSuccessUploadPyCode}
          startUploadCode={uploadCode}
          setFile={setFile}
          enableSaveCodeRequest={enableSaveCodeRequest}
          disableSaveCodeRequest={disableSaveCodeRequest}
          testCode={testCode}
          refresh={refresh}
        />
        )}
      {children}
    </div>
  );
});

export default CodeInfoView;

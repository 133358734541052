import React from "react";
import classnames from "classnames";
import { workloadInfo as styles, formControlLabel } from "./styles";
import { TextField, DropdownMenu, RadioGroup, AlertTitle, Alert } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formEventHandler, noop, isDataWorkloadPythonCodeEnabled } from "@util";
import {
  CreateWorkloadRequest,
  CreateWorkloadRequestAttributes,
  DEFAULT_WORKLOAD_TYPE_LABELS,
  WorkloadRuntimeOption,
  WorkloadType,
  WorkloadTypeLabels,
  WorkloadPythonCodeProperty,
  CodeView,
  PyConfigFile,
  PyDependencies
} from "@data";
import AdvancedSettings from "./AdvancedSettings";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

const RequirementTxtControlLabel = withStyles(formControlLabel)(FormControlLabel);
const DependenciesControlBox = withStyles(formControlLabel)(FormControlLabel);

export enum WorkloadLanguage {
  NONE = "",
  JAVA = "Java",
  PYTHON = "Python",
}

export enum JavaWorkloadVersion {
  NONE = "",
  JAVA8 = "v1.8",
}

export enum PythonWorkloadVersion {
  NONE = "",
  PYTHON312 = "v3.12",
}

export type WorkloadLanguageVersion = JavaWorkloadVersion | PythonWorkloadVersion | "";

export const WORKLOAD_LANGUAGES: WorkloadLanguage[] = isDataWorkloadPythonCodeEnabled() ? [
  WorkloadLanguage.JAVA,
  WorkloadLanguage.PYTHON,
] : [WorkloadLanguage.JAVA];

export const JAVA_WORKLOAD_LANGUAGE_VERSION_LABELS: string[] = [
  JavaWorkloadVersion.JAVA8,
];

export const PYTHON_WORKLOAD_LANGUAGE_VERSION_LABELS: string[] = [
  PythonWorkloadVersion.PYTHON312,
];

export const DEFAULT_WORKLOAD_TYPES: WorkloadType[] = [WorkloadType.FUNCTION, WorkloadType.QUERY];

export const DEFAULT_PY_CONFIG_FILES: PyConfigFile[] = [PyConfigFile.SETUP, PyConfigFile.PYPROJECT];

const mapWorkloadRuntimeOptionToWorkloadLanguage = (runtime: WorkloadRuntimeOption): WorkloadLanguage => {
  switch (runtime) {
    case WorkloadRuntimeOption.JAVA8:
      return WorkloadLanguage.JAVA;
    case WorkloadRuntimeOption.PYTHON312:
      return WorkloadLanguage.PYTHON;
    default:
      return WorkloadLanguage.NONE;
  }
};

const mapWorkloadRuntimeOptionToWorkloadLanguageVersion = (runtime: WorkloadRuntimeOption): WorkloadLanguageVersion => {
  switch (runtime) {
    case WorkloadRuntimeOption.JAVA8:
      return JavaWorkloadVersion.JAVA8;
    case WorkloadRuntimeOption.PYTHON312:
      return PythonWorkloadVersion.PYTHON312;
    default:
      return "";
  }
};

const mapWorkloadLanguageToLabel = (language: WorkloadLanguage): string => language;

const mapPyConfigToLabel = (configFile: PyConfigFile): string => configFile;

const mapWorkloadLanguageVersionToLabel = (version: string): React.ReactNode => {
  switch (version) {
    case JavaWorkloadVersion.JAVA8:
      return JavaWorkloadVersion.JAVA8;
    case PythonWorkloadVersion.PYTHON312:
      return PythonWorkloadVersion.PYTHON312;
    default:
      return "";
  }
};

const mapWorkloadLanguageVersionToWorkloadRuntimeOption = (version: WorkloadLanguageVersion): WorkloadRuntimeOption => {
  switch (version) {
    case JavaWorkloadVersion.JAVA8:
      return WorkloadRuntimeOption.JAVA8;
    case PythonWorkloadVersion.PYTHON312:
      return WorkloadRuntimeOption.PYTHON312;
    default:
      return WorkloadRuntimeOption.NONE;
  }
};

export interface Model {
  data?: CreateWorkloadRequest;
  types?: WorkloadType[];
  pyConfigFiles?: PyConfigFile[];
  typeLabels?: WorkloadTypeLabels;
  codeProperties?: WorkloadPythonCodeProperty;
}

export interface Actions {
  clearSelectedDataSets?: () => void;
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributes>) => void;
  mapWorkloadTypeToLabel?: (type: WorkloadType) => React.ReactNode | string | null;
  setCodeProperties?: (props: Partial<WorkloadPythonCodeProperty>) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const TypeView = withStyles(styles)((props: Props) => {

  const {
    classes,
    data = CreateWorkloadRequest.EMPTY,
    codeProperties = WorkloadPythonCodeProperty.EMPTY,
    types = DEFAULT_WORKLOAD_TYPES,
    pyConfigFiles = DEFAULT_PY_CONFIG_FILES,
    typeLabels = DEFAULT_WORKLOAD_TYPE_LABELS,
    mapWorkloadTypeToLabel = React.useCallback((type: WorkloadType) =>
      typeLabels[type] || null, [typeLabels]),
    setWorkloadData = noop,
    clearSelectedDataSets = noop,
    setCodeProperties = noop,
  } = props;

  const isFunctionType = React.useMemo(() => data.isFunctionType(), [data]);

  const [ selectedWorkloadLanguage, setSelectedWorkloadLanguage ] =
    React.useState<WorkloadLanguage>(mapWorkloadRuntimeOptionToWorkloadLanguage(data.getRuntime()));

  const [ selectedWorkloadLanguageVersion, setSelectedWorkloadLanguageVersion ] =
    React.useState<WorkloadLanguageVersion>(mapWorkloadRuntimeOptionToWorkloadLanguageVersion(data.getRuntime()));

  const [ selectedRuntime, setSelectedRuntime ] = React.useState<WorkloadRuntimeOption>(data.getRuntime());

  const languageVersionLabels = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.JAVA:
        return JAVA_WORKLOAD_LANGUAGE_VERSION_LABELS;
      case WorkloadLanguage.PYTHON:
        return PYTHON_WORKLOAD_LANGUAGE_VERSION_LABELS;
      default:
        return [];
    }
  }, [ selectedWorkloadLanguage ]);

  const entryPointHelperText = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.PYTHON:
        return "When the Python runtime environment is selected, the request handler is in the format [file name].[function name]. For example, if the entry point is index.entryPoint, then when the workload is triggered the entryPoint function in the index.py file is executed.";
      case WorkloadLanguage.JAVA:
        return "When the Java runtime environment is selected, the request entry point must be the class that implements the com.signify.iot.platform.bdi.dataprocessing.DataProcessingWorkload interface.";
      default:
        return "Specifies the function in the file to be executed first when you invoke a function.";
    }
  }, [ selectedWorkloadLanguage ]);

  const entryPointPlaceholder = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.PYTHON:
        return "workload.handler";
      case WorkloadLanguage.JAVA:
        return "com.signify.workload.WorkloadEventEntryPoint";
      default:
        return "Enter a request entry point";
    }
  }, [ selectedWorkloadLanguage ]);

  const showLanguageVersion = React.useMemo(() =>
      WorkloadLanguage.NONE !== selectedWorkloadLanguage,
    [ selectedWorkloadLanguage ]);

  const showEntryPoint = React.useMemo(() =>
      selectedRuntime !== WorkloadRuntimeOption.NONE,
    [ selectedRuntime ]);

  // Hiding these options till we have some clarity on the options backend will provide
  // const showCodeOptions = React.useMemo(() =>
  //     selectedWorkloadLanguage === WorkloadLanguage.PYTHON,
  //   [ selectedWorkloadLanguage ]);
  const showCodeOptions = false;

  const showConfigDependencies = React.useMemo(() =>
    codeProperties.codeView === CodeView.EDITOR,
    [codeProperties]);

  const onChangeCodeOption = React.useCallback((event: any) => {
    const { value } = event.target;
    setCodeProperties({
      codeView: value as CodeView,
    });
  }, [setCodeProperties]);

  const setNeedRequirementTxt = React.useCallback((value: boolean) => {
    setCodeProperties({
      requirementTxt: value,
    });
  }, [setCodeProperties]);

  const updatePyDependencies = React.useCallback((dependency: PyDependencies) => {
    const pyDependencies = codeProperties.pyDependencies.includes(dependency) ?
      codeProperties.pyDependencies.filter(dep => dep !== dependency) :
      [ ...codeProperties.pyDependencies, dependency ];
    setCodeProperties({ pyDependencies });
  }, [codeProperties, setCodeProperties]);

  const entryPointLabel = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.PYTHON:
        return "Request Handler";
      case WorkloadLanguage.JAVA:
        return "Request Entry Point";
      default:
        return "Request Entry Point";
    }
  }, [selectedWorkloadLanguage]);

  React.useEffect(() => {
    setSelectedRuntime(mapWorkloadLanguageVersionToWorkloadRuntimeOption(
      selectedWorkloadLanguageVersion));
  }, [
    selectedWorkloadLanguageVersion,
    setSelectedRuntime,
  ]);

  return (
    <div className={classnames("workloadType", classes.container)}>
      <label className={classes.title}>
        Configure Workload Type
      </label>
      <div className={classnames("typeContainer", classes.typeContainer)}>
        <DropdownMenu
          className={classnames("type", classes.dropdown, classes.selectType)}
          selectClassName={classes.dropdownMenuSelect}
          fullWidth={true}
          variant="outlined"
          dropdownMenuLabel="Type"
          dropdownMenuHint="Select the workload type"
          hideEmptyValue={true}
          dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          mapValueToLabel={mapWorkloadTypeToLabel}
          emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
          values={types}
          selectedValue={data.getType()}
          setSelectedValue={type => {
            clearSelectedDataSets();
            setWorkloadData({
              "@type": WorkloadType[type],
              type: WorkloadType[type],
              data: { inputs: [], outputs: [] },
              // Make sure to reset the workloadInternetConfig attribute if not a function type
              // in case the user previously selected a function type, added internet config and then
              // changed the type to query
              ...(type === WorkloadType.FUNCTION ? ({}) : ({
                workloadInternetConfig: {
                  accessibleDns: [],
                },
              })),
            });
          }}
        />
        {isFunctionType && (
          <React.Fragment>
            <DropdownMenu
              className={classnames("language", classes.dropdown, classes.language)}
              selectClassName={classes.dropdownMenuSelect}
              fullWidth={true}
              variant="outlined"
              dropdownMenuHint="Select language that the workload code will be written in"
              dropdownMenuLabel="Language"
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabel="Select language"
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              values={WORKLOAD_LANGUAGES}
              mapValueToLabel={mapWorkloadLanguageToLabel}
              selectedValue={selectedWorkloadLanguage}
              setSelectedValue={language => {
                setWorkloadData({ runtime: WorkloadRuntimeOption.NONE, entryPoint: "" });
                setSelectedRuntime(WorkloadRuntimeOption.NONE);
                setSelectedWorkloadLanguageVersion("");
                setSelectedWorkloadLanguage(language as WorkloadLanguage);
              }}
            />
            {showLanguageVersion && (
              <DropdownMenu
                className={classnames("languageVersion", classes.dropdown, classes.languageVersion)}
                selectClassName={classes.dropdownMenuSelect}
                fullWidth={true}
                variant="outlined"
                dropdownMenuHint="Select language version that the workload code will be written in"
                dropdownMenuLabel="Version"
                dropdownMenuLabelClassName={classes.dropdownMenuLabel}
                emptyValueLabel="Select version"
                emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
                values={languageVersionLabels}
                mapValueToLabel={mapWorkloadLanguageVersionToLabel}
                selectedValue={selectedWorkloadLanguageVersion}
                setSelectedValue={language => {
                  setWorkloadData({ runtime: WorkloadRuntimeOption.NONE, entryPoint: "" });
                  setSelectedWorkloadLanguageVersion(language as WorkloadLanguageVersion);
                  setSelectedRuntime(mapWorkloadLanguageVersionToWorkloadRuntimeOption(
                    language as WorkloadLanguageVersion));
                }}
              />
            )}
            {showEntryPoint && (
              <TextField
                className={classnames("entryPoint", classes.entryPoint)}
                size="small"
                name="entryPoint"
                color="secondary"
                variant="outlined"
                label={entryPointLabel}
                disableSpecialChars={true}
                placeholder={entryPointPlaceholder}
                InputLabelProps={{ shrink: true }}
                helperText={entryPointHelperText}
                value={data.getEntryPoint()}
                onChange={formEventHandler(updatedValue => setWorkloadData({
                  runtime: selectedRuntime,
                  entryPoint: updatedValue,
                }))}
              />
            )}
            {showCodeOptions && showEntryPoint && (
              <React.Fragment>
                <Typography
                  variant="h5"
                  className={classnames("codeOptionTitle", classes.codeOptionTitle)}
                >
                  Select Code Options for Python
                </Typography>
                <div className={classnames("codeOptionContainer", classes.codeOptionContainer)}>
                  <div className={classnames("codeOption", classes.codeOption)}>
                    <RadioGroup
                      className={classnames("radioGroup", "codeOptions", classes.radioGroup)}
                    >
                      <FormControlLabel
                        className={classnames("radio", "upload", classes.radio)}
                        label="Upload Code without Editing"
                        control={(
                          <Radio
                            value={CodeView.UPLOAD}
                            checked={codeProperties.codeView === CodeView.UPLOAD}
                            onChange={onChangeCodeOption}
                          />
                        )}
                      />
                      <FormControlLabel
                        className={classnames("radio", "editorWithUpload", classes.radio)}
                        label="Upload Code and edit"
                        control={(
                          <Radio
                            value={CodeView.EDITOR_WITH_UPLOAD}
                            checked={codeProperties.codeView === CodeView.EDITOR_WITH_UPLOAD}
                            onChange={onChangeCodeOption}
                          />
                        )}
                      />
                      <FormControlLabel
                        className={classnames("radio", "editor", classes.radio)}
                        label="Create a code template"
                        control={(
                          <Radio
                            value={CodeView.EDITOR}
                            checked={codeProperties.codeView === CodeView.EDITOR}
                            onChange={onChangeCodeOption}
                          />
                        )}
                      />
                    </RadioGroup>
                  </div>
                    <Alert severity="info" className={classnames("codeOptionInfo", classes.alert)}>
                      <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
                        <strong>
                          Code Options
                        </strong>
                      </AlertTitle>
                      <ul className={classnames("alertDescription", classes.alertDescription)}>
                        <li>
                          <strong>Upload Code without Editing: </strong>
                          Select this option if you have a code packaged in a zip file ready to be tested
                        </li>
                        <li>
                          <strong>Upload Code and edit: </strong>
                          Select this option if you want to upload your zip and edit the code before testing it
                        </li>
                        <li>
                          <strong>Create a code template: </strong>
                          Select this option if you want to start from scratch and create a new code template.
                        </li>
                      </ul>
                  </Alert>
                </div>
              </React.Fragment>
            )}
            {showConfigDependencies && showEntryPoint && (
              <React.Fragment>
                <div className={classnames("pyConfiguration", classes.pyConfiguration)}>
                  <DropdownMenu
                    className={classnames("configFile", classes.dropdown, classes.configFile)}
                    selectClassName={classes.dropdownMenuSelect}
                    variant="outlined"
                    dropdownMenuHint="Select configuration file for your python code"
                    dropdownMenuLabel="Configuration File"
                    dropdownMenuLabelClassName={classes.dropdownMenuLabel}
                    emptyValueLabel="Select configuration file"
                    emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
                    values={pyConfigFiles}
                    mapValueToLabel={mapPyConfigToLabel}
                    selectedValue={codeProperties.pyConfigFile}
                    setSelectedValue={configFile => {
                      setCodeProperties({ pyConfigFile: configFile as PyConfigFile });
                    }}
                  />
                  <RequirementTxtControlLabel
                    className={classnames("needRequirementTxt", classes.reqTxtFlag)}
                    label="Include requirement.txt"
                    control={(
                      <Checkbox
                        className={classnames("checkbox", classes.checkbox)}
                        value={codeProperties.requirementTxt}
                        color="primary"
                        checked={codeProperties.requirementTxt}
                        onChange={() => setNeedRequirementTxt(!codeProperties.requirementTxt)}
                      />
                    )}
                  />
                </div>
                <Typography variant="h5" style={{ fontWeight: 250 }}>
                  Add Python Dependencies
                </Typography>
                <DependenciesControlBox
                  className={classnames("dependencies", classes.depTxtFlag)}
                  label={PyDependencies.BOTO3}
                  control={(
                    <Checkbox
                      className={classnames("boto3", classes.checkbox)}
                      value={PyDependencies.BOTO3}
                      color="primary"
                      checked={codeProperties.pyDependencies.includes(PyDependencies.BOTO3)}
                      onChange={() => updatePyDependencies(PyDependencies.BOTO3)}
                    />
                  )}
                />
                <DependenciesControlBox
                  className={classnames("dependencies", classes.depTxtFlag)}
                  label={PyDependencies.REQUESTS}
                  control={(
                    <Checkbox
                      className={classnames("requests", classes.checkbox)}
                      value={PyDependencies.REQUESTS}
                      color="primary"
                      checked={codeProperties.pyDependencies.includes(PyDependencies.REQUESTS)}
                      onChange={() => updatePyDependencies(PyDependencies.REQUESTS)}
                    />
                  )}
                />
                <DependenciesControlBox
                  className={classnames("dependencies", classes.depTxtFlag)}
                  label={PyDependencies.PYTEST}
                  control={(
                    <Checkbox
                      className={classnames("pytest", classes.checkbox)}
                      value={PyDependencies.PYTEST}
                      color="primary"
                      checked={codeProperties.pyDependencies.includes(PyDependencies.PYTEST)}
                      onChange={() => updatePyDependencies(PyDependencies.PYTEST)}
                    />
                  )}
                />
                <DependenciesControlBox
                  className={classnames("dependencies", classes.depTxtFlag)}
                  label={PyDependencies.PANADAS}
                  control={(
                    <Checkbox
                      className={classnames("pandas", classes.checkbox)}
                      value={PyDependencies.PANADAS}
                      color="primary"
                      checked={codeProperties.pyDependencies.includes(PyDependencies.PANADAS)}
                      onChange={() => updatePyDependencies(PyDependencies.PANADAS)}
                    />
                  )}
                />
              </React.Fragment>
            )}
            {isFunctionType && (
              <AdvancedSettings data={data} setWorkloadData={setWorkloadData} />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default TypeView;

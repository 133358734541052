import React from "react";
import { useWorkloadManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseEditTagsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag?: string;
  tags: string[];
}

export interface UseEditTagsModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditTagsActions {
  editTags: () => void;
  reset: () => void;
}

type Props = UseEditTagsProps;
type Model = UseEditTagsModel;
type Actions = UseEditTagsActions;
type Result = [Model, Actions];

export const useEditTags = (props: Props): Result => {

  const {
    name = "",
    version = 1,
    etag = "",
    tags = [],
    defaultErrorMessage = "Failed to edit workload tags",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() =>
    WorkloadManagerClient.editWorkloadTagsApi(name, version, etag, tags),
    [WorkloadManagerClient, name, version, tags]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: editTags, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Edit Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editTags,
    reset
  }), [
    editTags,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditTags;

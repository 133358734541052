import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { mapTriggerTypeToOptionName } from "@hooks";
import { isEmptyString, isValidNumber, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  Button,
  DataSets,
  DataSetsListColumn,
  DataSetsListItemData,
  DropdownCronMenu,
  DropdownMenu,
  Link,
  Radio,
  TextField,
} from "@components";
import {
  AddTriggerRequest,
  CronDay,
  CronMonth,
  CronOption,
  CronWeek,
  Trigger,
  TriggerRateUnit,
  TriggerType,
  UpdateTriggerRequest,
  WorkloadDataSetsAttributes,
} from "@data";
import {
  cronForParsing,
  getCronDayOfWeek,
  getCronMonth,
  getCronValues,
  getFullRange,
  listNextDates,
  mapToRateUnit,
} from "../helpers";
import WorkloadCompleteView from "./WorkloadCompleteView";

export interface Model {
  name: string;
  version: number;
  isEdit?: boolean;
  data?: UpdateTriggerRequest;
  trigger?: Trigger;
  dataInputs?: WorkloadDataSetsAttributes[];
  showLoadingIndicator?: boolean;
  validRateMinutes?: boolean;
  cronValues?: string;
}

export interface Actions {
  setTriggerData?: (obj: UpdateTriggerRequest) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const TriggerInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    name,
    version,
    isEdit = false,
    data = UpdateTriggerRequest.EMPTY,
    trigger = Trigger.EMPTY,
    dataInputs = [],
    cronValues = "",
    validRateMinutes = false,
    showLoadingIndicator = false,
    setTriggerData = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputRateProps = {
    className: classnames("textField", classes.textField, classes.rateTextField),
    spellCheck: false,
  };

  // Data Lake Trigger Information
  const [selectedDataSet, setSelectedDataSet] = React.useState<DataSetsListItemData[]>([]);

  const dataSets = React.useMemo(() => {
    const allSets = dataInputs.map(dataset =>
      DataSetsListItemData.fromWorkloadDataSetAttributes(dataset.dataSetAlias, dataset.accountId));
    return selectedDataSet.length > 0 ? selectedDataSet.slice() :
      isEdit ? allSets.filter((dataSet) => dataSet.getName() !== trigger.getDataSetAlias()) : allSets;
  }, [dataInputs, selectedDataSet, isEdit, trigger]);

  const updateSelectedDataSet = React.useCallback((updatedDataSets: DataSetsListItemData[]) => {
    setSelectedDataSet(updatedDataSets);
    const dataSet = updatedDataSets.slice().pop();
    setTriggerData({ dataSetAlias: dataSet?.getName(), accountId: dataSet?.getAccountId() });
  }, [setSelectedDataSet, setTriggerData]);

  const [minute, setMinute] = React.useState<string[]>([]);
  const [hour, setHour] = React.useState<string[]>([]);
  const [month, setMonth] = React.useState<string[]>([]);
  const [dayOfWeek, setDayOfWeek] = React.useState<string[]>([]);
  const [dayOfMonth, setDayOfMonth] = React.useState<string[]>([]);
  const [isTypeCron, setIsTypeCron] = React.useState<boolean>(false);
  const [cronOption, setCronOption] = React.useState<CronOption>(CronOption.DAY_OF_WEEK);
  const [rateValueInput, setRateValueInput] = React.useState(30);
  const [unitType, setUnitType] = React.useState<TriggerRateUnit>(TriggerRateUnit.MINUTES);

  const weekIndex = React.useCallback((s) =>
    Object.values(CronWeek).indexOf(CronWeek[s.toUpperCase()]) + 1, [CronWeek]);

  const compareIndex = ((a: string, b: string, obj: any) =>
    Object.values(obj).indexOf(obj[a.toUpperCase()]) - Object.values(obj).indexOf(obj[b.toUpperCase()]));

  const clearMinute = React.useCallback(() => setMinute([]), [setMinute]);
  const clearHour = React.useCallback(() => setHour([]), [setHour]);
  const clearMonth = React.useCallback(() => setMonth([]), [setMonth]);

  const clearDayOfWeekAndMonth = React.useCallback(() => {
    setDayOfMonth([]);
    setDayOfWeek([]);
  }, [setDayOfMonth, setDayOfWeek]);

  const clearAll = React.useCallback(() => {
    setCronOption(CronOption.DAY_OF_WEEK);
    clearMinute();
    clearHour();
    clearMonth();
    clearDayOfWeekAndMonth();
    setUnitType(TriggerRateUnit.MINUTES);
    setTriggerData({ ...AddTriggerRequest.EMPTY.toJS() });
  }, [
    setCronOption,
    clearMinute,
    clearHour,
    clearMonth,
    clearDayOfWeekAndMonth,
    setUnitType,
    setTriggerData,
  ]);

  const updateMonth = React.useCallback((selectedValue: string[] = []) => {
    setMonth(selectedValue.sort((a, b) => compareIndex(a, b, CronMonth)));
  }, [setMonth, compareIndex, CronMonth]);

  const updateDayOfMonth = React.useCallback((selectedValue: string[] = []) => {
    setDayOfMonth(selectedValue.sort((a, b) => parseFloat(a) - parseFloat(b)));
  }, [setDayOfMonth]);

  const updateDayOfWeek = React.useCallback((selectedValue: string[] = []) => {
    setDayOfWeek(selectedValue.sort((a, b) => compareIndex(a, b, CronDay)));
  }, [setDayOfWeek, compareIndex, CronDay]);

  const updateHour = React.useCallback((selectedValue: string[] = []) => {
    setHour(selectedValue.sort((a, b) => parseFloat(a) - parseFloat(b)));
  }, [setHour]);

  const updateMinute = React.useCallback((selectedValue: string[] = []) => {
    setMinute(selectedValue.sort((a, b) => parseFloat(a) - parseFloat(b)));
  }, [setMinute]);

  const filterNumbers = React.useCallback((s: string[]) => {
    return s.filter(i => isValidNumber(i));
  }, [isValidNumber]);

  const selectedMinutes = React.useCallback((s: string[]) => {
    updateMinute(filterNumbers(s));
  }, [updateMinute, filterNumbers]);

  const selectedHours = React.useCallback((s: string[]) => {
    updateHour(filterNumbers(s));
  }, [updateHour, filterNumbers]);

  const selectedDayOfMonth = React.useCallback((s: string[]) => {
    setCronOption(CronOption.DAY_OF_MONTH);
    updateDayOfMonth(filterNumbers(s));
  }, [updateDayOfMonth, setCronOption, filterNumbers]);

  const selectedMonth = React.useCallback((s: string[]) => {
    s = s.filter(i => Object.values(CronMonth).includes(CronMonth[i.toUpperCase()]));
    updateMonth(s);
  }, [updateMonth]);

  const selectedDayOfWeek = React.useCallback((s: string[]) => {
    s = s.filter(i => Object.values(CronDay).includes(CronDay[i.toUpperCase()]));
    setCronOption(CronOption.DAY_OF_WEEK);
    updateDayOfWeek(s);
  }, [updateDayOfWeek, setCronOption]);

  const onChangeValue = React.useCallback((inputRate: number,
                                           inputUnit: TriggerRateUnit = data.getRateUnit()) => {
    const singleValueUnit = () => {
      switch (inputUnit) {
        case TriggerRateUnit.MINUTES:
          return TriggerRateUnit.MINUTE;
        case TriggerRateUnit.HOURS:
          return TriggerRateUnit.HOUR;
        case TriggerRateUnit.DAYS:
          return TriggerRateUnit.DAY;
        default:
          return inputUnit;
      }
    };

    const multiValueUnit = () => {
      switch (inputUnit) {
        case TriggerRateUnit.MINUTE:
          return TriggerRateUnit.MINUTES;
        case TriggerRateUnit.HOUR:
          return TriggerRateUnit.HOURS;
        case TriggerRateUnit.DAY:
          return TriggerRateUnit.DAYS;
        default:
          return inputUnit;
      }
    };

    const rateValue = inputRate > 1 ? Math.floor(inputRate) : 1;
    const rateUnit = inputRate <= 1 ? singleValueUnit() : multiValueUnit();
    const scheduleExpression = `rate(${rateValue} ${rateUnit.toLowerCase()})`;

    setRateValueInput(rateValue);
    setTriggerData({ rateUnit, rateValue, scheduleExpression });
  }, [data, setRateValueInput, setTriggerData]);

  const onChangeRate = React.useCallback((e, unit: TriggerRateUnit) => {
    const inputRate = !isEmptyString(e.target.value) ? parseFloat(e.target.value) : 0;
    return onChangeValue(inputRate, unit);
  }, [onChangeValue]);

  const onChangeType = React.useCallback((value: boolean) => () => {
    setIsTypeCron(value);
    clearAll();
  }, [setIsTypeCron, clearAll]);

  const selectedRateUnit = React.useCallback((s: string) => {
    setUnitType(TriggerRateUnit[s]);
    onChangeRate({
      target: { value: rateValueInput.toString() }
    }, TriggerRateUnit[s]);
  }, [setUnitType, onChangeRate, rateValueInput]);

  const groupConsecutive = React.useCallback((input: number[] = []) => {
    return input.reduce((arr: any[], val: number, i: number, a: number[]) => {
      if (!i || val !== a[i - 1] + 1) { arr.push([]); }
      arr[arr.length - 1].push(val);
      return arr;
    }, []);
  }, []);

  const getDashed = React.useCallback((input: string[] = [], obj: any = null) => {
    let inputArr = input.filter((s: string) => !s.includes("every"));

    inputArr = obj ? inputArr.map((s: string) =>
      `${Object.values(obj).indexOf(obj[s.toUpperCase()]) + 1}`) : inputArr;

    const arr = groupConsecutive(inputArr.map((s: string) => parseFloat(s)));
    return arr.length ? arr.map((s) => s.length > 1 ? `${s[0]}-${s[s.length - 1]}` : `${s}`).join(",") : "*";
  }, []);

  const validCron = React.useMemo(() =>
    minute.length && hour.length && isTypeCron,
    [minute, hour, isTypeCron]);

  const nextTriggerDates = React.useMemo(() =>
    validCron ? listNextDates(cronForParsing(cronValues), 10) : [],
    [validCron, cronValues]);

  const mapRateUnitToLabel = React.useCallback((value: TriggerRateUnit) => {
    switch (value) {
      case TriggerRateUnit.MINUTES:
        return "Minutes";
      case TriggerRateUnit.HOURS:
        return "Hours";
      case TriggerRateUnit.DAYS:
        return "Days";
      default:
        return null;
    }
  }, []);

  const resetButton = React.useMemo(() => (
    <Button
      onClick={clearAll}
      className={classnames("clearButton", classes.clearButton)}
      classes={{ label: classnames("label", classes.clearButtonLabel) }}
    >
      RESET
    </Button>
  ), [clearAll]);

  const patternButton = React.useCallback((
    isCron: boolean, checked: boolean, id: string, description: string
  ) => (
    <Button
      name={id}
      onClick={onChangeType(isCron)}
      className={classnames(id, classes.scheduleButton,
        { [classes.scheduleButtonChecked]: checked }
      )}
    >
      <Radio
        name={`${id}Radio`}
        checked={checked}
        disableRipple={true}
        onChange={onChangeType(isCron)}
        className={classnames(`${id}Radio`, classes.scheduleRadio)}
      />
      {description}
    </Button>
  ), [onChangeType]);

  const patternInfo = React.useCallback((
    title: string, description: string, link: string = ""
  ) => (
    <div>
      <div className={classnames("expressionTitle", classes.expressionTitle)}>
        <div>{title}</div>
        {!isEmptyString(link) && (
          <React.Fragment>
            <div className={classnames("divider", classes.divider)}>|</div>
            <Link
              className={classnames("infoLink", classes.link)}
              href={link}
            >
              Learn More
            </Link>
          </React.Fragment>
        )}
      </div>
      <div className={classnames("scheduleDescription", classes.scheduleDescription)}>
        {description}
      </div>
    </div>
  ), []);

  React.useEffect(() => {
    if (isEdit) {
      if (trigger !== undefined && trigger.getType() === TriggerType.SCHEDULE) {
        const scheduleExpression = trigger.getScheduleExpression();
        const isCron = scheduleExpression.includes("cron");
        setIsTypeCron(isCron);

        if (isCron) {
          const [minutes, hours, days, months, daysOfWeek] = getCronValues(scheduleExpression);

          const isWeek = days === "?";
          setCronOption(isWeek ? CronOption.DAY_OF_WEEK : CronOption.DAY_OF_MONTH);

          setMinute(minutes.split(",").map((s: string) => s === "0" ? "00" : s));

          const hoursRange = hours === "*" ? [] :
            hours.split(",").reduce((h: string[], s: string) => h.concat(getFullRange(s)), []);
          setHour(hoursRange);

          const daysOfMonth = isWeek ? [] :
            days.split(",").reduce((d: string[], s: string) => d.concat(getFullRange(s)), []);
          setDayOfMonth(daysOfMonth);

          const monthsRange = months === "*" ? [] :
            months.split(",").reduce((m: string[], s: string) => m.concat(getFullRange(s)), []);
          setMonth(monthsRange.map((s: string) => getCronMonth(parseInt(s, 10))));

          const daysOfWeekRange = !isWeek || daysOfWeek === "*" ? [] :
            daysOfWeek.split(",").reduce((d: string[], s: string) => d.concat(getFullRange(s)), []);
          setDayOfWeek(daysOfWeekRange.map((s: string) => getCronDayOfWeek(parseInt(s, 10))));
        } else {
          const [rate, unit] = scheduleExpression.substring(5, scheduleExpression.length - 1).split(" ");
          setRateValueInput(Number(rate));
          setUnitType(mapToRateUnit(unit));
        }
      }
    }
  }, [trigger]);

  React.useEffect(() => {
    const isDayOfMonth = cronOption === CronOption.DAY_OF_MONTH;
    const isDayOfWeek = cronOption === CronOption.DAY_OF_WEEK;

    if (isTypeCron) {
      setTriggerData({
        scheduleExpression:
          `cron(${[
            getDashed(minute),
            getDashed(hour),
            isDayOfMonth ? getDashed(dayOfMonth) : "?",
            getDashed(month, CronMonth),
            isDayOfWeek ? getDashed(dayOfWeek, CronDay) : "?"
          ].join(" ")} *)`
      });
    }

  }, [
    isTypeCron,
    cronOption,
    month,
    dayOfMonth,
    dayOfWeek,
    hour,
    minute,
    CronMonth,
    CronDay,
    getDashed,
    weekIndex
  ]);

  return (
    <div className={classnames("triggerInfo", classes.container)}>
      <div className={classnames("typeContainer")}>
        <label className={classnames("title", classes.title)}>
          {isEdit ? "Update the" : "Add a"} {mapTriggerTypeToOptionName(data.getType() as TriggerType)?.toLowerCase() + " "}
          trigger for {name} version {version}
        </label>
          {data.getType() === TriggerType.DATA_LAKE && (
            <div className="dataLakeInfo">
              <p className={classnames("dataSetsTitle", classes.dataSetsTitle)}>
                Select a data set from the workload data inputs to use as a trigger
              </p>
              <DataSets
                className={classnames("dataSetsList", classes.dataSetsList)}
                items={selectedDataSet.length > 0 ? selectedDataSet : dataSets}
                selectable={true}
                selectAllDisabled={true}
                selectedItems={selectedDataSet}
                setSelectedItems={updateSelectedDataSet}
                showSummary={false}
                showSearch={false}
                columns={[DataSetsListColumn.NAME, DataSetsListColumn.ACCOUNT]}
              />
            </div>
          )
          }
          {data.getType() === TriggerType.SCHEDULE && (
            <React.Fragment>
            <div className={classnames("schedule", classes.schedule)}>
              <div className={classnames("scheduleTitle", classes.scheduleTitle)}> Schedule Builder</div>
              <div className={classnames("scheduleContent", classes.scheduleContent)}>
                <div>Schedule pattern</div>
                <div className={classnames("scheduleDescription", classes.scheduleDescription)}>
                  Choose the schedule type that best meets your needs.
                </div>
                {patternButton(false, !isTypeCron, "rateButton",
                  "A schedule that runs at a regular rate, such as every 30 minutes."
                )}
                {patternButton(true, isTypeCron, "cronButton",
                  "A fine-grained schedule that runs at a specific time, such as 10:00 a.m. UTC every Wednesday of the week."
                )}
                {isTypeCron ? (
                  <React.Fragment>
                    <div>
                      {patternInfo("Cron expression",
                        "Define the cron expression for the schedule. Minutes and hours are required fields. " +
                        "The day of the month and day of the week fields are mutually exclusive.",
                        "https://en.wikipedia.org/wiki/Cron#Cron_expression"
                      )}
                    </div>
                    <div className={classnames("cronSettings", classes.settings)}>
                      <div>cron (</div>
                      <DropdownCronMenu
                        className={classnames("cronMinute", classes.dropdownCron)}
                        hideEmptyValue={true}
                        emptyValueLabel="minute(s)"
                        dropdownMenuHint={"Minute(s)"}
                        values={["00", "15", "30", "45"]}
                        selectedValues={minute}
                        setSelectedValues={selectedMinutes}
                        onClear={clearMinute}
                      />
                      <DropdownCronMenu
                        className={classnames("cronHour", classes.dropdownCron)}
                        columns={5}
                        hideEmptyValue={true}
                        emptyValueLabel="hour(s)"
                        dropdownMenuHint={"Hour(s)"}
                        values={[...Array(24)].map((_, i) => `${i}`)}
                        selectedValues={hour}
                        setSelectedValues={selectedHours}
                        onClear={clearHour}
                      />
                      <DropdownCronMenu
                        className={classnames("cronDayOfMonth", classes.dropdownCron, {
                          [classes.dropdownCronDisabled]:
                            cronOption === CronOption.DAY_OF_WEEK && dayOfWeek.length
                        })}
                        columns={7}
                        hideEmptyValue={true}
                        emptyValueLabel={"day(s) of month"}
                        dropdownMenuHint={"Day(s) of month"}
                        values={[...Array(31)].map((_, i) => `${i + 1}`)}
                        selectedValues={dayOfMonth}
                        setSelectedValues={selectedDayOfMonth}
                        onClear={clearDayOfWeekAndMonth}
                      />
                      <DropdownCronMenu
                        className={classnames("cronMonth", classes.dropdownCron)}
                        columns={3}
                        hideEmptyValue={true}
                        emptyValueLabel="month(s)"
                        dropdownMenuHint={"Month(s)"}
                        values={[
                          CronMonth.JANUARY,
                          CronMonth.FEBRUARY,
                          CronMonth.MARCH,
                          CronMonth.APRIL,
                          CronMonth.MAY,
                          CronMonth.JUNE,
                          CronMonth.JULY,
                          CronMonth.AUGUST,
                          CronMonth.SEPTEMBER,
                          CronMonth.OCTOBER,
                          CronMonth.NOVEMBER,
                          CronMonth.DECEMBER,
                        ]}
                        selectedValues={month}
                        setSelectedValues={selectedMonth}
                        onClear={clearMonth}
                      />
                      <DropdownCronMenu
                        className={classnames("cronDayOfWeek", classes.dropdownCron, {
                          [classes.dropdownCronDisabled]:
                            cronOption === CronOption.DAY_OF_MONTH && dayOfMonth.length
                        })}
                        hideEmptyValue={true}
                        emptyValueLabel="day(s) of week"
                        dropdownMenuHint={"Day(s) of week"}
                        values={[
                          CronDay.MONDAY,
                          CronDay.TUESDAY,
                          CronDay.WEDNESDAY,
                          CronDay.THURSDAY,
                          CronDay.FRIDAY,
                          CronDay.SATURDAY,
                          CronDay.SUNDAY,
                        ]}
                        selectedValues={dayOfWeek}
                        setSelectedValues={selectedDayOfWeek}
                        onClear={clearDayOfWeekAndMonth}
                      />
                      <div>)</div>
                      {resetButton}
                    </div>
                    <div className={classnames("scheduleNextDatesTitle", classes.scheduleNextDatesTitle)}>
                      Next {validCron ? nextTriggerDates.length : "10"} trigger date(s)
                    </div>
                    {!validCron ? (
                      <div className={classnames("scheduleNextDatesInfo", classes.scheduleNextDatesInfo)}>
                        Scheduled dates will be generated here upon receiving a valid cron expression.
                      </div>
                    ) : (
                      <ul>
                        {nextTriggerDates.map((date, index) => (
                          <li
                            className={classnames("scheduleNextDates", classes.scheduleNextDates)}
                            key={`${date}-${index}`}
                          >
                            {date}
                          </li>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>
                      {patternInfo("Rate expression", "Enter a value and the unit of time to run the schedule.")}
                      </div>
                      <div className={classnames("rateSettings", classes.settings)}>
                        <div>rate (</div>
                        <TextField
                          className={classnames("rateValue",
                            classes.textField, classes.inputField, classes.rateValue
                          )}
                          type="number"
                          variant="outlined"
                          autoComplete="off"
                          margin="none"
                          fullWidth={false}
                          name="rateValue"
                          helperText="Value"
                          value={rateValueInput}
                          inputProps={inputRateProps}
                          disabled={showLoadingIndicator}
                          onChange={(e) => onChangeRate(e, unitType)}
                          FormHelperTextProps={formHelperTextProps}
                          InputLabelProps={inputLabelProps}
                        />
                        <DropdownMenu
                          className={classnames("rateUnit", classes.dropdownUnit)}
                          hideEmptyValue={true}
                          dropdownMenuHint="Unit"
                          variant="outlined"
                          mapValueToLabel={mapRateUnitToLabel}
                          values={[TriggerRateUnit.MINUTES, TriggerRateUnit.HOURS, TriggerRateUnit.DAYS]}
                          selectedValue={unitType}
                          setSelectedValue={selectedRateUnit}
                        />
                        <div>)</div>
                        {resetButton}
                      </div>
                      {!validRateMinutes && (
                        <div className={classnames("warn", classes.warn)}>
                          The value must be &gt;= 10 for the minutes unit.
                        </div>
                      )}
                  </React.Fragment>
                )}
                </div>
            </div>
            </React.Fragment>
          )}
          {data.getType() === TriggerType.WORKLOAD_COMPLETE && (
            <WorkloadCompleteView
              currentWorkload={name}
              data={data}
              setTriggerData={setTriggerData}
            />
          )}
        </div>
      </div>
  );
});

export default TriggerInfo;

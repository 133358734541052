import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ProjectView, { ProjectViewListItem, ProjectViewListItemType } from "./ProjectView";
import EditorView, { EditorViewItem } from "./EditorView";
import styles from "./styles";
import {
  AlertSeverity,
  ErrorView,
  MissingAlertView,
  SaveButton,
  UploadWorkloadCode,
} from "@components";
import JSZip from "jszip";
import Typography from "@material-ui/core/Typography";
import { Prompt } from "react-router-dom";
import PreventAccidentalBackNavigation from "@components/prevent-accidental-back-navigation";

const DEFAULT_FILES: ProjectViewListItem[] = [];

export interface Model {
  className?: string;
  workloadName?: string;
  version?: number;
  zipFile?: any;
  codeDownloadLoading?: boolean;
  codeUploadLoading?: boolean;
  maxNumOpenEditorTabs?: number;
  codeUploadDisabled?: boolean;
  codePackageSuccess?: boolean;
  showDownloadErrorView?: boolean;
  fileUploadSuccess?: boolean;
  downloadErrorMessage?: string;
  fileUploadErrorMessage?: string;
  statusCode?: number;
  saveButtonLabel?: React.ReactNode;
  uploadButtonLabel?: React.ReactNode;
  testButtonLabel?: React.ReactNode;
}

export interface Actions {
  onSuccessUploadPyCode?: () => void;
  setFile?: (file: File) => void;
  startUploadCode?: () => void;
  testCode?: () => void;
  enableSaveCodeRequest?: () => void;
  disableSaveCodeRequest?: () => void;
  refresh?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WorkloadCodeEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    maxNumOpenEditorTabs = 5,
    codeDownloadLoading,
    codeUploadLoading,
    workloadName = "",
    version = 1,
    zipFile,
    saveButtonLabel,
    uploadButtonLabel,
    testButtonLabel,
    codeUploadDisabled,
    showDownloadErrorView,
    downloadErrorMessage,
    fileUploadErrorMessage,
    statusCode,
    fileUploadSuccess = false,
    codePackageSuccess,
    onSuccessUploadPyCode = noop,
    setFile = noop,
    testCode = noop,
    startUploadCode = noop,
    enableSaveCodeRequest = noop,
    disableSaveCodeRequest = noop,
    refresh = noop,
    children,
  } = props;

  const [files, setFiles] = React.useState<ProjectViewListItem[]>(DEFAULT_FILES);

  const showEmptyView = React.useMemo(() => files.length === 0, [files]);

  const [initialFiles, setInitialFiles] = React.useState<ProjectViewListItem[]>([]);

  const [ openUploadDialog, setOpenUploadDialog ] = React.useState(false);

  const [ showSaveError, setShowSaveError ] = React.useState(false);

  const uploadCodeDialog = React.useCallback(() => {
    disableSaveCodeRequest();
    setOpenUploadDialog(true);
  }, [setOpenUploadDialog, disableSaveCodeRequest]);

  const closeUploadDialog = React.useCallback(() => {
    setOpenUploadDialog(false);
  }, [setOpenUploadDialog]);

  const onSuccess = React.useCallback(() => {
    closeUploadDialog();
    setFiles([]);
    setInitialFiles([]);
    refresh();
  }, [closeUploadDialog, refresh, setFiles, setInitialFiles]);

  const noLocalChanges = React.useMemo(() => {
    if (initialFiles.length === 0) {
      return true;
    }

    const getFileMap = (filesMap: ProjectViewListItem[]): Map<string, ProjectViewListItem> => {
      const map = new Map<string, ProjectViewListItem>();
      const iterate = (items: ProjectViewListItem[]) => {
        items.forEach(item => {
          map.set(item.id, item);
          if (item.type === ProjectViewListItemType.FOLDER) {
            iterate(item.children || []);
          }
        });
      };
      iterate(filesMap);
      return map;
    };

    const currentFileMap = getFileMap(files);
    const initialFileMap = getFileMap(initialFiles);

    if (currentFileMap.size !== initialFileMap.size) {
      return false;
    }

    for (const [id, file] of Array.from(currentFileMap.entries())) {
      const initialFile = initialFileMap.get(id);
      if (!initialFile || file.code !== initialFile.code || file.name !== initialFile.name) {
        return false;
      }
    }

    return true;
  }, [files, initialFiles]);

  const dialogWarningMessage = React.useMemo(() =>
    files.length > 0 ? "This code will replace the current uploaded or unsaved code" : ""
  , [files]);

  const flattenedFiles = React.useMemo<ProjectViewListItem[]>(() => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] => {
      return filesToIterate.reduce((flattened, file) => {
        const { type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          return flattened.concat(iterate(fileContents));
        } else if (type === ProjectViewListItemType.FILE) {
          return flattened.concat(file);
        } else {
          return flattened;
        }
      }, [] as ProjectViewListItem[]);
    };
    return iterate(files);
  }, [files]);

  const editorViewItems = React.useMemo<EditorViewItem[]>(() =>
    flattenedFiles
      .filter(({ open, selected }) => open || selected)
      .map(({ id, name: label, code: itemCode, selected }) => new EditorViewItem({
        id,
        label,
        code: itemCode,
        selected,
      })), [flattenedFiles]);

  const collapseFolder = React.useCallback(id => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (fileId === id) {
          return { ...file, collapsed: true };
        } else if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          return file;
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const expandFolder = React.useCallback(id => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (fileId === id) {
          return { ...file, collapsed: false };
        } else if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          return file;
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const openFile = React.useCallback(id => {
    const openFiles = editorViewItems.find(item => id === item.getId()) != null
      ? editorViewItems.slice() : editorViewItems.slice(0, maxNumOpenEditorTabs - 1);
    const openFileIds = openFiles.map(item => item.getId());
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          const selected = fileId === id;
          return { ...file, selected, open: selected || openFileIds.indexOf(fileId) >= 0 };
        }
      });
    setFiles(iterate(files));
  }, [maxNumOpenEditorTabs, editorViewItems, files, setFiles]);

  const setSelectedFile = React.useCallback(id => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, open, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          const selected = fileId === id;
          return {
            ...file,
            selected,
            open: open || selected,
          };
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const closeTab = React.useCallback(id => {
    const wasSelected = (editorViewItems.find(item => id === item.getId()) || EditorViewItem.EMPTY).isSelected();
    const newlySelectedEditorViewItem = !wasSelected
      ? EditorViewItem.EMPTY
      : editorViewItems.filter(item => id !== item.getId()).pop() || EditorViewItem.EMPTY;
    const newlySelectedFileId = newlySelectedEditorViewItem.getId();
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else if (fileId === id) {
          return { ...file, open: false, selected: false };
        } else {
          return {
            ...file,
            ...(!wasSelected ? ({}) : ({
              selected: fileId === newlySelectedFileId,
            })),
          };
        }
      });
    setFiles(iterate(files));
  }, [editorViewItems, files, setFiles, setSelectedFile]);

  const updateCode = React.useCallback((id, updatedCode) => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (fileId === id) {
          return { ...file, code: updatedCode };
        } else if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          return file;
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const renameFile = React.useCallback((id, updatedName) => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (fileId === id) {
          return { ...file, name: updatedName };
        } else if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          return file;
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const deleteFile = React.useCallback(id => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate
        .filter(({ id: fileId }) => fileId !== id)
        .map(file => {
          const { type, children: fileContents = [] } = file;
          if (type === ProjectViewListItemType.FOLDER) {
            return {
              ...file,
              children: iterate(fileContents),
            };
          } else {
            return file;
          }
        });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const addFile = React.useCallback((id: string, name: string = "", parentId: string = "") => {

    const newFile = {
      id,
      name,
      type: ProjectViewListItemType.FILE,
      code: "",
    };

    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          if (fileId === parentId) {
            return {
              ...file,
              collapsed: false,
              children: fileContents.concat(newFile),
            };
          }
          return {
            ...file,
            children: iterate(fileContents),
          };
        }
        return file;
      });

    if (isEmptyString(parentId)) {
      setFiles(files.concat(newFile));
    } else {
      setFiles(iterate(files));
    }

  }, [files, setFiles]);

  const addFolder = React.useCallback((id: string, name: string = "", parentId: string = "") => {

    const newFolder = {
      id,
      name,
      type: ProjectViewListItemType.FOLDER,
      code: "",
    };

    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          if (fileId === parentId) {
            return {
              ...file,
              collapsed: false,
              children: fileContents.concat(newFolder),
            };
          }
          return {
            ...file,
            children: iterate(fileContents),
          };
        }
        return file;
      });

    if (isEmptyString(parentId)) {
      setFiles(files.concat(newFolder));
    } else {
      setFiles(iterate(files));
    }

  }, [files, setFiles]);

  const uploadButtonDisabled = React.useMemo(() =>
    codeUploadDisabled,
    [codeUploadDisabled]);

  const testCodeEnabled = React.useMemo(() =>
    codePackageSuccess && noLocalChanges, [codePackageSuccess, noLocalChanges]);

  const saveCode = React.useCallback(async () => {

    enableSaveCodeRequest();

    const zip = new JSZip();

    files.forEach(file => {
      if (file.type === ProjectViewListItemType.FILE) {
        zip.file(file.name, file.code || "");
      } else if (file.type === ProjectViewListItemType.FOLDER) {
        const addZipFolder = (folder: ProjectViewListItem, folderPath: string) => {
          (folder.children || []).forEach(child => {
            if (child.type === ProjectViewListItemType.FILE) {
              zip.file(`${folderPath}/${child.name}`, child.code || "");
            } else if (child.type === ProjectViewListItemType.FOLDER) {
              addZipFolder(child, `${folderPath}/${child.name}`);
            }
          });
        };
        addZipFolder(file, file.name);
      }
    });

    const content = await zip.generateAsync({type: "blob"});
    const newZipFile = new File([content], `${workloadName}.zip`, {type: "application/zip"});

    setFile(newZipFile);
    startUploadCode();
  }, [
    files,
    setFile,
    startUploadCode,
    workloadName,
    enableSaveCodeRequest,
    setInitialFiles
  ]);

  const reloadCode = React.useCallback(() => {
    setFiles([]);
    setInitialFiles([]);
    onSuccessUploadPyCode();
  }, [setFiles, setInitialFiles, onSuccessUploadPyCode]);

  React.useEffect(() => {
    if (initialFiles.length === 0 && files.length > 0) {
      setInitialFiles(files);
    }
  }, [files, initialFiles]);

  React.useEffect(() => {
    if (fileUploadSuccess) {
      setInitialFiles(files);
    } else {
      setInitialFiles(initialFiles);
    }
  }, [fileUploadSuccess]);

  React.useEffect(() => {
    if (!isEmptyString(fileUploadErrorMessage)) {
      setShowSaveError(true);
    }
  }, [fileUploadErrorMessage]);

  return (
    <div className={classnames("workloadCodeEditor", className, classes.container)}>
      <PreventAccidentalBackNavigation />
      {codeUploadLoading && <div className={classnames("overlay", classes.overlay)} />}
      <div className={classnames("controls", classes.controls)}>
        <label className={classnames("title", classes.editorTitle)}>
          Code Editor
        </label>
        {!noLocalChanges && !codeUploadLoading && !showEmptyView && (
          <MissingAlertView
            className={classnames("codeNotSavedAlert", classes.missingAlert)}
            severity={AlertSeverity.INFO}
            showAction={false}
            message={(
              <React.Fragment>
                <Typography variant="body1">
                  Local changes not saved
                </Typography>
              </React.Fragment>
            )}
          />
        )}
        {codeUploadLoading && (
          <MissingAlertView
            className={classnames("uploadingAlert", classes.missingAlert)}
            severity={AlertSeverity.WARNING}
            showAction={false}
            message={(
              <React.Fragment>
                <Typography variant="body1">
                  Saving! Do not navigate away from this page.
                </Typography>
              </React.Fragment>
            )}
          />
        )}
        <SaveButton
          className={classnames("uploadButton", classes.uploadCodeButton)}
          color={"primary"}
          label={uploadButtonLabel}
          downloadIndicatorSize={50}
          disabled={uploadButtonDisabled}
          save={uploadCodeDialog}
        />
        <SaveButton
          className={classnames("testCodeButton", classes.testCodeButton)}
          color={"primary"}
          label={testButtonLabel}
          downloadIndicatorSize={50}
          disabled={!testCodeEnabled}
          save={testCode}
        />
        <SaveButton
          className={classnames("saveCodeButton", classes.saveCodeButton)}
          color={"primary"}
          label={saveButtonLabel}
          loading={codeUploadLoading}
          downloadIndicatorSize={50}
          disabled={noLocalChanges || showEmptyView}
          save={saveCode}
        />
      </div>
      {showSaveError && (
        <ErrorView
          title={"Error saving code"}
          message={fileUploadErrorMessage}
          statusCode={statusCode}
          showCloseIcon={true}
          onClickCloseIcon={() => setShowSaveError(false)}
        />
      )}
      <div className={classnames("ide", classes.ide)}>
        <ProjectView
          className={classnames("projectView", classes.projectView)}
          files={files}
          zipFile={zipFile}
          loading={codeDownloadLoading}
          codeUploadDisabled={codeUploadDisabled}
          showDownloadErrorView={showDownloadErrorView}
          downloadErrorMessage={downloadErrorMessage}
          workloadName={workloadName}
          collapseFolder={collapseFolder}
          expandFolder={expandFolder}
          setSelectedFile={openFile}
          renameFile={renameFile}
          deleteFile={deleteFile}
          addFile={addFile}
          addFolder={addFolder}
          setFiles={setFiles}
          reloadCode={reloadCode}
          setInitialFiles={setInitialFiles}
        />
          <EditorView
            className={classnames("editor", classes.editor)}
            items={editorViewItems}
            closeTab={closeTab}
            setSelectedTab={setSelectedFile}
            readonlyMode={codeUploadDisabled || codeUploadLoading}
            updateCode={updateCode}
          />
        </div>
        <UploadWorkloadCode
          name={workloadName}
          version={version}
          open={openUploadDialog}
          closeDialog={closeUploadDialog}
          onSuccess={onSuccess}
          warningMessage={dialogWarningMessage}
          fileType={"zip"}
        />
        <Prompt
          when={!noLocalChanges}
          message={"Local Code changes not saved. Are you sure you want to leave this page?"}
        />
        {children}
      </div>
      );
  });

export default WorkloadCodeEditor;
import React from "react";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
  useUiPersonalizationClient
} from "@hooks";
import { GetUiPreferenceResponse } from "@network";

type SuccessResponse = GetUiPreferenceResponse;

export interface UseGetPreferenceProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  preferenceName: string;
}

export interface UseGetPreferenceModel extends UseApiRequestModel<SuccessResponse> {
  preference: string[];
}

export interface UseGetPreferenceActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetPreferenceProps;
type Model = UseGetPreferenceModel;
type Actions = UseGetPreferenceActions;
type Result = [Model, Actions];

export const useGetPreference = (props: Props): Result => {

  const {
    preferenceName,
    defaultErrorMessage = "Failed to get preference",
    ...otherProps
  } = props;

  const UiPersonalizationClient = useUiPersonalizationClient();

  const makeApiRequest = React.useCallback(() => UiPersonalizationClient.getPreference(preferenceName),
    [UiPersonalizationClient, preferenceName]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const preference = React.useMemo<string[]>(() => {
    return successResponse ? successResponse.entityValue.modules : [];
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    preference,
  }), [
    baseModel,
    preference,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetPreference;

import React from "react";
import { Vector } from "@data";
import classnames from "classnames";
import Button from "@components/button";
import camelCase from "lodash/camelCase";
import Table from "@material-ui/core/Table";
import SortButton from "@components/sort-button";
import { SvgIconProps } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import ExpandButton from "@components/expand-button";
import OpenInNewTabIcon from "@material-ui/icons/Launch";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PaginatedListTableCell from "./PaginatedListTableCell";
import { TooltipIcon, TooltipIconLabel } from "@components/tooltip";
import ActionsMenu, { ActionMenuItem } from "@components/actions-menu";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { checkboxEventHandler, clickHandler, isEmptyString, noop, stopPropagation } from "@util";
import styles from "./styles";
import { ErrorView as Error } from "@components/error-view";

type StylesProps = WithStyles<typeof styles>;

export const NoResultsView = withStyles(styles)((props: StylesProps & {
  label?: string,
  numColumns?: number,
}) => {

  const { classes, label = "No Results", numColumns } = props;

  return (
    <TableRow className={classnames("noResultsView", classes.tableBodyRow)}>
      <TableCell
        className={classnames(classes.tableBodyColumn, classes.center)}
        colSpan={numColumns}
      >
        <p className={classnames("noResultsLabel", classes.label)}>{label}</p>
      </TableCell>
    </TableRow>
  );
});

const LoadingView = withStyles(styles)((props: StylesProps & {
  numColumns?: number,
  message?: string,
}) => {

  const { classes, numColumns, message = "Loading..." } = props;

  return (
    <TableRow className={classnames("loadingView", classes.tableBodyRow)}>
      <TableCell
        className={classnames(classes.tableBodyColumn, classes.center)}
        colSpan={numColumns}
      >
        <p className={classnames("loadingLabel", classes.label, classes.italic)}>{message}</p>
      </TableCell>
    </TableRow>
  );
});

const LoadMoreView = withStyles(styles)((props: StylesProps & {
  numColumns?: number,
  message?: string,
  buttonDisabled?: boolean,
  buttonClassName?: string,
  loadMore?: () => void,
}) => {

  const {
    classes,
    numColumns,
    message = "Load more",
    buttonDisabled,
    buttonClassName,
    loadMore,
  } = props;

  return (
    <TableRow className={classnames("loadMoreView", classes.tableBodyRow)}>
      <TableCell
        className={classnames(classes.tableBodyColumn, classes.center)}
        colSpan={numColumns}
      >
        <Button
          className={classnames("loadMoreButton", buttonClassName, classes.loadMoreButton)}
          disabled={buttonDisabled}
          onClick={clickHandler(loadMore)}
        >
          {message}
        </Button>
      </TableCell>
    </TableRow>
  );
});

const AddItemView = withStyles(styles)((props: StylesProps & {
  numColumns?: number,
  message?: string,
  buttonDisabled?: boolean,
  buttonClassName?: string,
  addItem?: () => void,
}) => {

  const {
    classes,
    numColumns,
    message = "Add Item",
    buttonDisabled,
    buttonClassName,
    addItem,
  } = props;

  return (
    <TableRow className={classnames("addItemView", classes.tableBodyRow)}>
      <TableCell
        className={classnames(classes.tableBodyColumn, classes.center)}
        colSpan={numColumns}
      >
        <Button
          className={classnames("addItemButton", buttonClassName, classes.addItemButton)}
          disabled={buttonDisabled}
          onClick={clickHandler(addItem)}
        >
          {message}
        </Button>
      </TableCell>
    </TableRow>
  );
});

const ErrorView = withStyles(styles)((props: StylesProps & {
  numColumns?: number,
  error?: string,
  retry?: () => void;
  statusCode?: number;
}) => {

  const {
    classes,
    numColumns,
    error = "Failed to load items",
    retry,
    statusCode,
  } = props;

  return (
    <TableRow className={classnames("errorView", classes.tableBodyRow)}>
      <TableCell
        className={classnames(classes.tableBodyColumn, classes.center, classes.errorView)}
        colSpan={numColumns}
      >
        <Error
          className={classnames("errorView", classes.error)}
          message={error}
          retry={retry}
          statusCode={statusCode}
        />
      </TableCell>
    </TableRow>
  );
});

export const TableCellCheckbox = withStyles(styles)((props: StylesProps & {
  className?: string,
  checked?: boolean,
  onChange?: (checked: boolean) => void;
}) => {

  const { classes, className, checked, onChange = noop } = props;

  return (
    <TableCell className={classnames("tableCellCheckbox", className, classes.checkboxContainer)}>
      <Checkbox
        className={classnames("checkbox", classes.checkbox, { checked })}
        checked={checked}
        onChange={checkboxEventHandler(onChange)}
        onClick={stopPropagation}
      />
    </TableCell>
  );
});

const TableCellExpand = withStyles(styles)((props: StylesProps & {
  className?: string,
  expanded?: boolean,
  hideButton?: boolean,
  onChange?: (checked: boolean) => void;
}) => {

  const { classes, className, expanded, hideButton, onChange = noop } = props;

  return (
    <TableCell className={classnames("tableCellExpand", className, classes.expandContainer)}>
      {!hideButton && (
        <ExpandButton
          className={classnames("expand", classes.expand, {expanded})}
          expanded={expanded}
          setExpanded={onChange}
        />
      )}
    </TableCell>
  );
});

export interface ComparableItem<Item> {
  equals: (otherItem: Item) => boolean;
}

export interface PaginatedListModel<Item extends ComparableItem<Item>> {
  className?: string;
  items?: Item[];
  actions?: ActionMenuItem[];
  columns?: string[];
  columnTooltips?: { [key: string]: string };
  sortedColumn?: string;
  sortOrderAscending?: boolean;
  sortButtonDisabled?: boolean;
  showSortButton?: boolean;
  selectable?: boolean;
  selectAllDisabled?: boolean;
  selectedItems?: Item[];
  maxNumSelectedItems?: number;
  expandable?: boolean;
  expandedItems?: Item[];
  error?: string;
  noResultsLabel?: string;
  loadMoreLabel?: string;
  loadMoreButtonClassName?: string;
  loadMoreButtonDisabled?: boolean;
  showErrorView?: boolean;
  showLoadingView?: boolean;
  loadingLabel?: string;
  showMoreInfoLabel?: string;
  addItemLabel?: string;
  addItemButtonClassName?: string;
  showMoreIcon?: React.ComponentType<SvgIconProps>;
  showNoResultsView?: boolean;
  showLoadMoreButton?: boolean;
  showAddItemButton?: boolean;
  tableLayoutFixed?: boolean;
  fixedRowHeight?: boolean;
  alternateRowColor?: boolean;
  whiteSpaceNoWrapCols?: number[];
  placeholder?: string;
  placeholderClassName?: string;
  placeholderEnabled?: boolean;
  placeholderEl?: React.ReactNode;
  autoLoadMoreEnabled?: boolean;
  autoLoadMoreThreshold?: number;
  autoLoadMoreMaxRequests?: number;
  autoLoadMoreDelayMs?: number;
  showColumnNames?: boolean;
  hideExpandButton?: boolean;
  statusCode?: number;
}

export interface PaginatedListActions<Item extends ComparableItem<Item>> {
  isColumnSortable?: (column: string) => boolean;
  onClickToggleSortOrder?: () => void;
  onClickColumn?: (column: string) => void;
  onClickItem?: (item: Item) => void;
  onClickAction?: (item: Item, action: ActionMenuItem) => void;
  onClickLoadMore?: () => void;
  onClickAddItem?: () => void;
  onClickShowMoreInfo?: (item: Item) => void;
  onClickShowMoreInfoInNewTab?: (item: Item) => string;
  onClickEditIcon?: (item: Item) => void;
  onClickDeleteIcon?: (item: Item) => void;
  setSelectedItems?: (items: Item[]) => void;
  setExpandedItems?: (items: Item[]) => void;
  renderItem?: (item: Item, column: string, row: number) => React.ReactNode;
  renderExpandedItem?: (item: Item, row: number) => React.ReactNode;
  mapItemToAction?: (item: Item, action: ActionMenuItem) => ActionMenuItem;
  getItemColor?: (item: Item) => string;
}

type Props<Item extends ComparableItem<Item>> =
  StylesProps & PaginatedListModel<Item> & PaginatedListActions<Item>;

export const PaginatedList = withStyles(styles)(
  <Item extends ComparableItem<Item>, >(props: Props<Item>) => {

    const {
      classes,
      className = "",
      items = [],
      actions = [],
      columns = [],
      columnTooltips = {},
      sortedColumn,
      sortOrderAscending,
      sortButtonDisabled,
      showSortButton,
      selectable,
      selectAllDisabled,
      selectedItems = [],
      maxNumSelectedItems = 0,
      expandable,
      expandedItems = [],
      error,
      noResultsLabel,
      loadMoreLabel,
      showMoreInfoLabel = "Show more info",
      addItemLabel,
      addItemButtonClassName,
      loadMoreButtonClassName,
      loadMoreButtonDisabled,
      showErrorView,
      showLoadingView,
      loadingLabel,
      showNoResultsView,
      showLoadMoreButton,
      tableLayoutFixed = true,
      showMoreIcon = OpenInNewTabIcon,
      placeholder = "-",
      placeholderClassName,
      placeholderEnabled = true,
      placeholderEl,
      fixedRowHeight = true,
      alternateRowColor = false,
      whiteSpaceNoWrapCols = [],
      autoLoadMoreEnabled = true,
      autoLoadMoreThreshold = 20,
      autoLoadMoreMaxRequests = 5,
      autoLoadMoreDelayMs = 250,
      showColumnNames = true,
      hideExpandButton = false,
      showAddItemButton = false,
      onClickItem,
      onClickAction,
      isColumnSortable = () => true,
      statusCode,
      onClickColumn = noop,
      onClickLoadMore: loadMore = noop,
      onClickShowMoreInfo = noop,
      onClickShowMoreInfoInNewTab,
      onClickEditIcon = noop,
      onClickDeleteIcon = noop,
      onClickAddItem = noop,
      onClickToggleSortOrder: toggleSortOrder = noop,
      setSelectedItems = noop,
      setExpandedItems = noop,
      renderItem = () => null,
      renderExpandedItem = () => null,
      mapItemToAction = (_item: Item, action: ActionMenuItem) => action,
      getItemColor = () => "",
    } = props;

    const showOnlySelectedItems = React.useMemo(() =>
        selectable && maxNumSelectedItems > 0 && selectedItems.length >= maxNumSelectedItems,
      [selectable, maxNumSelectedItems, selectedItems]);

    const autoLoadMoreEnabledAndAllowed = React.useMemo(() =>
        autoLoadMoreEnabled && !showOnlySelectedItems,
      [autoLoadMoreEnabled, showOnlySelectedItems]);

    const visibleItems = React.useMemo(() =>
        showOnlySelectedItems ? selectedItems : items,
      [showOnlySelectedItems, selectedItems, items]);

    const numItems = React.useMemo(() => visibleItems.length, [visibleItems]);

    const isEmpty = React.useMemo(() => numItems === 0, [numItems]);

    const isClickable = React.useMemo(() =>
      (typeof onClickItem === "function") && (onClickItem !== noop), [onClickItem]);

    const showActions = React.useMemo(() =>
      actions.length > 0 && typeof onClickAction === "function", [actions, onClickAction]);

    const showMoreInfo = React.useMemo(() =>
      (typeof onClickShowMoreInfo === "function") && (onClickShowMoreInfo !== noop || onClickShowMoreInfoInNewTab),
      [onClickShowMoreInfo, onClickShowMoreInfoInNewTab]);

    const showEditIcon = React.useMemo(() =>
      (typeof onClickEditIcon === "function") && (onClickEditIcon !== noop), [onClickEditIcon]);

    const showDeleteIcon = React.useMemo(() =>
      (typeof onClickDeleteIcon === "function") && (onClickDeleteIcon !== noop), [onClickDeleteIcon]);

    const showCheckboxColumn = React.useMemo(() => selectable && !isEmpty, [selectable, isEmpty]);

    const showSelectAllCheckbox = React.useMemo(() =>
      showCheckboxColumn && !selectAllDisabled, [showCheckboxColumn, selectAllDisabled]);

    const numColumns = React.useMemo(() =>
        columns.length +
        (expandable ? 1 : 0) +
        (showActions ? 1 : 0) +
        (showMoreInfo ? 1 : 0) +
        (showEditIcon ? 1 : 0) +
        (showDeleteIcon ? 1 : 0) +
        (showCheckboxColumn ? 1 : 0),
      [columns, expandable, showActions, showCheckboxColumn, showMoreInfo, showEditIcon, showDeleteIcon]);

    const isSortable = React.useMemo(() => {

      if (showSortButton) {
        return true;
      }

      return !showLoadMoreButton && !sortButtonDisabled && numItems > 1 &&
        (onClickColumn !== noop || toggleSortOrder !== noop);

    }, [
      showSortButton,
      showLoadMoreButton,
      sortButtonDisabled,
      numItems,
      onClickColumn,
      toggleSortOrder
    ]);

    const isItemSelected = React.useCallback((item: Item) =>
        selectedItems.some((selectedItem: Item) => item.equals(selectedItem)),
      [selectedItems]);

    const isItemExpanded = React.useCallback((item: Item) =>
        expandedItems.some((expandedItem: Item) => item.equals(expandedItem)),
      [expandedItems]);

    const isSelectAllChecked = React.useMemo(() =>
        !isEmpty && visibleItems.every(isItemSelected),
      [isEmpty, visibleItems, isItemSelected]);

    const onSelectAllChanged = React.useCallback((checked: boolean) => {
      if (checked) {
        // Add all the items that do not have a corresponding selected item
        // We do this instead of making a copy of the items array because there is no guarantee
        // that the items array contains all the selected items (eg: rendering a filtered list).
        setSelectedItems(selectedItems.concat(
          visibleItems.filter(item => !isItemSelected(item))));
      } else {
        // Remove all the selected items that have a corresponding item
        // We do this instead of clearing the list because there is no guarantee that
        // the items array contains all the selected items (eg: rendering a filtered list).
        setSelectedItems(selectedItems.filter(
          selectedItem => !visibleItems.some(item => selectedItem.equals(item))));
      }
    }, [visibleItems, selectedItems, isItemSelected, setSelectedItems]);

    const onSelectItemChanged = React.useCallback((selectedItem: Item) => (checked: boolean) => {
      if (checked) {
        setSelectedItems(selectedItems.concat(selectedItem));
      } else {
        setSelectedItems(selectedItems.filter((item: Item) => !item.equals(selectedItem)));
      }
    }, [selectedItems, setSelectedItems]);

    const onExpandItemChanged = React.useCallback((expandedItem: Item) => (expanded: boolean) => {
      if (expanded) {
        setExpandedItems(expandedItems.concat(expandedItem));
      } else {
        setExpandedItems(expandedItems.filter((item: Item) => !item.equals(expandedItem)));
      }
    }, [expandedItems, setExpandedItems]);

    const onItemClicked = React.useCallback((row: number) => {
      if (onClickItem && numItems > row) {
        return () => onClickItem(visibleItems[row]);
      } else {
        return noop;
      }
    }, [onClickItem, numItems, visibleItems]);

    const showMoreInfoInNewTab = React.useMemo(() =>
        !!onClickShowMoreInfoInNewTab,
      [onClickShowMoreInfoInNewTab]);

    const onShowMoreInfoClicked = React.useCallback((row: number) => {
      if (onClickShowMoreInfo && numItems > row) {
        return () => onClickShowMoreInfo(visibleItems[row]);
      } else {
        return noop;
      }
    }, [onClickShowMoreInfo, numItems, visibleItems]);

    const onEditIconClicked = React.useCallback((row: number) => {
      if (onClickEditIcon && numItems > row) {
        return () => onClickEditIcon(visibleItems[row]);
      } else {
        return noop;
      }
    }, [onClickEditIcon, numItems, visibleItems]);

    const onDeleteIconClicked = React.useCallback((row: number) => {
      if (onClickDeleteIcon && numItems > row) {
        return () => onClickDeleteIcon(visibleItems[row]);
      } else {
        return noop;
      }
    }, [onClickDeleteIcon, numItems, visibleItems]);

    const onActionClicked = React.useCallback((row: number) => (action: ActionMenuItem) => {
      if (onClickAction && numItems > row) {
        onClickAction(visibleItems[row], action);
      }
    }, [onClickAction, numItems, visibleItems]);

    const mapItemToActions = React.useCallback((item: Item) =>
        actions.map((action: ActionMenuItem) => mapItemToAction(item, action)),
      [actions, mapItemToAction]);

    const errorView = React.useMemo(() => !showErrorView ? null : (
      <ErrorView numColumns={numColumns} error={error} statusCode={statusCode} retry={loadMore} />
    ), [showErrorView, numColumns, error, statusCode, loadMore]);

    // Counter used to ensure that we do not perform more than 5 automatic "load more" requests
    // in a row when the list contains less than 20 items and a paginated response is available.
    const [autoLoadMoreCounter, setAutoLoadMoreCounter] = React.useState(0);

    // Flag used to determine if we should automatically reset the counter used to prevent us
    // from automatically fetching the next page of results more than 5 times. We need this
    // additional flag because otherwise if we fetch a list of items and zero items come back,
    // but a paginated response is available, we could potentially end up in an infinite loop if
    // the backend keeps returning no results with a paginated response.
    const [canResetAutoLoadMoreCounter, setCanResetAutoLoadMoreCounter] = React.useState(numItems > 0);

    // Reset counter used for limiting number of automatic load more fetches when
    // user manually clicks the load more button in case the next response contains
    // a paging response without any new entries.
    const onClickLoadMore = React.useCallback(() => {
      setAutoLoadMoreCounter(0);
      loadMore();
    }, [setAutoLoadMoreCounter, loadMore]);

    // When the list is cleared before fetching first page and was previously non-empty, reset
    // counter used to determine whether we should fetch the next page of results
    const shouldAutoResetLoadMoreCounter = React.useMemo(() =>
        numItems === 0 &&
        showLoadingView &&
        !showLoadMoreButton &&
        autoLoadMoreCounter > 0 &&
        canResetAutoLoadMoreCounter,
      [
        numItems,
        showLoadingView,
        showLoadMoreButton,
        autoLoadMoreCounter,
        canResetAutoLoadMoreCounter,
      ]);

    const isLoadingViewVisible = React.useMemo(() => {
      if (showLoadingView) {
        return true;
      }
      if (autoLoadMoreEnabledAndAllowed && numItems < autoLoadMoreThreshold && showLoadMoreButton) {
        return autoLoadMoreCounter < autoLoadMoreMaxRequests;
      }
      return false;
    }, [
      showLoadingView,
      numItems,
      autoLoadMoreEnabledAndAllowed,
      autoLoadMoreThreshold,
      showLoadMoreButton,
      autoLoadMoreCounter,
      autoLoadMoreMaxRequests,
    ]);

    const loadingView = React.useMemo(() => !isLoadingViewVisible ? null : (
      <LoadingView numColumns={numColumns} message={loadingLabel} />
    ), [isLoadingViewVisible, numColumns, loadingLabel]);

    // We should not simultaneously show the loading view and the no results view
    const noResultsView = React.useMemo(() =>
      isLoadingViewVisible || showLoadMoreButton || !showNoResultsView ? null : (
        <NoResultsView numColumns={numColumns} label={noResultsLabel} />
      ), [isLoadingViewVisible, showLoadMoreButton, showNoResultsView, numColumns, noResultsLabel]);

    // We should avoid showing the load more button if the load more button is either visible
    // or if we are on the verge of automatically attempting to fetch the next page
    const loadMoreButton = React.useMemo(() =>
      showOnlySelectedItems || isLoadingViewVisible || !showLoadMoreButton ? null : (
        <LoadMoreView
          numColumns={numColumns}
          message={loadMoreLabel}
          buttonClassName={loadMoreButtonClassName}
          buttonDisabled={loadMoreButtonDisabled}
          loadMore={onClickLoadMore}
        />
      ), [
      showOnlySelectedItems,
      isLoadingViewVisible,
      showLoadMoreButton,
      numColumns,
      loadMoreLabel,
      loadMoreButtonClassName,
      loadMoreButtonDisabled,
      onClickLoadMore,
    ]);

    const addItemButton = React.useMemo(() => isLoadingViewVisible || !showAddItemButton ? null : (
      <AddItemView
        numColumns={numColumns}
        message={addItemLabel}
        buttonClassName={addItemButtonClassName}
        buttonDisabled={!showAddItemButton}
        addItem={onClickAddItem}
      />
    ), [
      isLoadingViewVisible,
      showLoadMoreButton,
      numColumns,
      addItemLabel,
      addItemButtonClassName,
      showAddItemButton,
      onClickAddItem,
    ]);

    // Fetch the next page of results if the list contains less than 20 entries and a paginated
    // response is available; however, limit the # of automatic fetches to at most 5 requests
    React.useEffect(() => {

      if (!autoLoadMoreEnabledAndAllowed) {
        return noop;
      }

      if (numItems < autoLoadMoreThreshold && showLoadMoreButton && !showLoadingView) {
        if (autoLoadMoreCounter < autoLoadMoreMaxRequests) {
          let ignore = false;
          const timer = setTimeout(() => {
            if (!ignore) {
              setAutoLoadMoreCounter(autoLoadMoreCounter + 1);
              loadMore();
            }
          }, autoLoadMoreDelayMs);
          return () => {
            ignore = true;
            clearTimeout(timer);
          };
        }
      }

      return noop;

    }, [
      autoLoadMoreEnabledAndAllowed,
      numItems,
      autoLoadMoreThreshold,
      autoLoadMoreMaxRequests,
      showLoadMoreButton,
      showLoadingView,
      autoLoadMoreDelayMs,
      loadMore,
      setAutoLoadMoreCounter,
    ]);

    // We can reset the counter used for automatically fetching the next page of results
    // under the following conditions:
    //   1. Items have been rendered in the table
    //   2. The loading view is not visible and the load more button is not visible
    //   3. The counter has maxed out the loading view is not visible
    React.useEffect(() => {
      setCanResetAutoLoadMoreCounter(
        numItems > 0 ||
        (!showLoadingView && !showLoadMoreButton) ||
        (autoLoadMoreCounter === autoLoadMoreMaxRequests && !showLoadingView));
    }, [
      numItems,
      showLoadingView,
      showLoadMoreButton,
      autoLoadMoreCounter,
      autoLoadMoreMaxRequests,
      setCanResetAutoLoadMoreCounter,
    ]);

    // Reset the counter used for automatically fetching next page of results when list is refreshed
    React.useEffect(() => {
      if (shouldAutoResetLoadMoreCounter) {
        setAutoLoadMoreCounter(0);
      }
    }, [shouldAutoResetLoadMoreCounter, setAutoLoadMoreCounter]);

    return (
      <Table
        className={classnames(className, classes[className], classes.table, {
          [classes.tableLayoutFixed]: tableLayoutFixed,
        })}
      >
        {showColumnNames && (
          <TableHead className="tableHeader">
            <TableRow className={classnames("tableHeaderRow", classes.tableHeaderRow)}>
              {showSelectAllCheckbox && (
                <TableCellCheckbox
                  className={classnames(
                    "tableHeaderColumn",
                    "selectAllCheckbox",
                    classes.tableHeaderColumn,
                    classes.checkboxColumn,
                  )}
                  checked={isSelectAllChecked}
                  onChange={onSelectAllChanged}
                />
              )}
              {!showSelectAllCheckbox && showCheckboxColumn && (
                <TableCell
                  className={classnames(
                    "tableHeaderColumn",
                    "selectedColumn",
                    classes.tableHeaderColumn,
                    classes.checkboxColumn,
                  )}
                />
              )}
              {expandable && (
                <TableCell
                  className={classnames(
                    "tableHeaderColumn",
                    "expandColumn",
                    classes.tableHeaderColumn,
                    classes.expandColumn,
                  )}
                />
              )}
              {columns.map((column: string, index: number) => {

                const sortable = isSortable && isColumnSortable(column);

                return (
                  <TableCell
                    key={`header-column-${index}`}
                    className={classnames(
                      "tableHeaderColumn",
                      camelCase(column),
                      classes.tableHeaderColumn,
                    )}
                  >
                    {!sortable && (
                      <React.Fragment>
                        {!columnTooltips[column] && (
                          <label>{column}</label>

                        )}
                        {columnTooltips[column] && (
                          <TooltipIconLabel
                            label={column}
                            iconTooltip={columnTooltips[column]}
                          />
                        )}
                      </React.Fragment>
                    )}
                    {sortable && (
                      <SortButton
                        label={column}
                        tooltip={columnTooltips[column]}
                        active={column === sortedColumn}
                        ascending={sortOrderAscending}
                        setSortedColumn={() => onClickColumn(column)}
                        toggleSortOrder={toggleSortOrder}
                      />
                    )}
                  </TableCell>
                );
              })}
              {showActions && (
                <TableCell
                  key={`header-actions-column`}
                  className={classnames(
                    "actions",
                    "tableHeaderColumn",
                    classes.tableHeaderColumn,
                    classes.actionsColumn,
                  )}
                >
                  <label>Actions</label>
                </TableCell>
              )}
              {showMoreInfo && (
                <TableCell
                  key={`header-showMoreInfo-column`}
                  className={classnames(
                    "showMoreInfo",
                    "tableHeaderColumn",
                    classes.tableHeaderColumn,
                    classes.showMoreInfoColumn,
                  )}
                />
              )}

              {showEditIcon && (
                <TableCell
                  key={`header-showEditIcon-column`}
                  className={classnames(
                    "showEditIcon",
                    "tableHeaderColumn",
                    classes.tableHeaderColumn,
                  )}
                />
              )}
              {showDeleteIcon && (
                <TableCell
                  key={`header-showDeleteIcon-column`}
                  className={classnames(
                    "showDeleteIcon",
                    "tableHeaderColumn",
                    classes.tableHeaderColumn,
                  )}
                />
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody className="tableBody">
          {visibleItems.map((item: Item, rowIndex: number) => {

            const selected = isItemSelected(item);
            const expanded = isItemExpanded(item);
            const rowColor = getItemColor(item);

            return (
              <React.Fragment key={`detail-row-${rowIndex}`}>
                <TableRow
                  className={classnames("tableBodyRow", {
                    [classes.tableBodyRow]: fixedRowHeight,
                    [classes.clickable]: isClickable,
                    [classes.selectable]: selectable,
                    [classes.selected]: selected,
                    [classes.expandable]: expandable,
                    [classes.expanded]: expanded,
                    [classes.coloredRow]: alternateRowColor ? rowIndex % 2 === 0 : false,
                    [classes[rowColor]]: !isEmptyString(rowColor),
                  })}
                  hover={isClickable}
                  selected={selected}
                  onClick={onItemClicked(rowIndex)}
                >
                  {selectable && (
                    <TableCellCheckbox
                      className={classnames(
                        "tableBodyColumn",
                        "selectItemCheckbox",
                        classes.tableBodyColumn, {
                          [classes.checkboxColumn]: showCheckboxColumn,
                        },
                      )}
                      checked={selected}
                      onChange={onSelectItemChanged(item)}
                    />
                  )}
                  {expandable && (
                    <TableCellExpand
                      className={classnames(
                        "tableHeaderColumn",
                        "expandColumn",
                        classes.tableHeaderColumn,
                        classes.expandColumn,
                      )}
                      expanded={expanded}
                      hideButton={hideExpandButton}
                      onChange={onExpandItemChanged(item)}
                    />
                  )}
                  {columns.map((column: string, columnIndex: number) => (
                    <PaginatedListTableCell
                      key={`row-${rowIndex}-column-${columnIndex}`}
                      className={classnames(
                        "tableBodyColumn",
                        camelCase(column),
                        classes.tableBodyColumn, {
                          [classes.whiteSpaceNoWrapCols]: whiteSpaceNoWrapCols.includes(columnIndex),
                        },
                      )}
                      item={item}
                      placeholder={placeholder}
                      placeholderClassName={placeholderClassName}
                      placeholderEnabled={placeholderEnabled}
                      placeholderEl={placeholderEl}
                    >
                      {renderItem(item, column, rowIndex)}
                    </PaginatedListTableCell>
                  ))}
                  {showActions && (
                    <TableCell
                      key={`row-${rowIndex}-actions-column`}
                      className={classnames(
                        "tableBodyColumn",
                        "actions",
                        classes.tableBodyColumn,
                        classes.actionsColumn,
                      )}
                    >
                      <ActionsMenu
                        className="actions"
                        buttonVariant="icon"
                        actions={mapItemToActions(item)}
                        onClickAction={onActionClicked(rowIndex)}
                      />
                    </TableCell>
                  )}
                  {showMoreInfo && (
                    <TableCell
                      key={`row-${rowIndex}-show-more-info`}
                      className={classnames(
                        "tableBodyColumn",
                        "showMoreInfo",
                        classes.tableBodyColumn,
                        classes.showMoreInfoColumn,
                      )}
                    >
                      <TooltipIcon
                        className={classnames(
                          "tableHeaderColumn",
                          "showMoreInfoButton",
                          classes.showMoreInfoButton,
                        )}
                        icon={showMoreIcon}
                        size={Vector.square(20)}
                        tooltipText={showMoreInfoLabel}
                        {...(showMoreInfoInNewTab ? {
                          target: "_blank",
                          rel: "noopener noreferrer",
                          onClick: stopPropagation,
                          href: onClickShowMoreInfoInNewTab ?
                            onClickShowMoreInfoInNewTab(visibleItems[rowIndex]) : "",
                        } : { onClick: clickHandler(onShowMoreInfoClicked(rowIndex)) })}
                      />
                    </TableCell>
                  )}
                  {showEditIcon && (
                    <TableCell
                      key={`row-${rowIndex}-edit-icon`}
                      className={classnames(
                        "tableBodyColumn",
                        "editIconButton",
                        classes.tableBodyColumn,
                      )}
                    >
                      <Button
                        className={classnames(
                          "tableHeaderColumn",
                          "editIconButton",
                          classes.showEditIconButton,
                        )}
                        onClick={clickHandler(onEditIconClicked(rowIndex))}
                        name="editIconButton"
                      >
                        <EditIcon className={classes.icon}/>
                      </Button>
                    </TableCell>
                  )}
                  {showDeleteIcon && (
                    <TableCell
                      key={`row-${rowIndex}-delete-icon`}
                      className={classnames(
                        "tableBodyColumn",
                        "deleteIconButton",
                        classes.tableBodyColumn,
                      )}
                    >
                      <Button
                        className={classnames(
                          "tableHeaderColumn",
                          "deleteIconButton",
                          classes.showDeleteIconButton,
                        )}
                        onClick={clickHandler(onDeleteIconClicked(rowIndex))}
                        name="deleteIconButton"
                      >
                        <DeleteIcon className={classes.icon}/>
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
                {expanded && (
                  <TableRow
                    className={classnames("tableBodyRow", "tableBodyRowExpanded",
                      classes.tableBodyRow,
                      classes.tableBodyRowExpanded,
                    )}
                  >
                    <TableCell
                      className={classnames("tableBodyColumn", "tableBodyColumnExpanded",
                        classes.tableBodyColumn,
                        classes.tableBodyColumnExpanded,
                        classes.center,
                      )}
                      colSpan={numColumns}
                    >
                      {renderExpandedItem(item, rowIndex)}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
          {loadingView}
          {errorView}
          {noResultsView}
          {loadMoreButton}
          {addItemButton}
        </TableBody>
      </Table>
    );
  });

export default PaginatedList;

import createStyles from "@material-ui/core/styles/createStyles";
import ModuleIcon from "@material-ui/icons/DataUsage";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999,
      pointerEvents: "all",
    },
    controls: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      position: "relative",
      marginBottom: 20,
    },
    editorTitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      minWidth: 100,
      alignSelf: "center",
      fontSize: 24,
      lineHeight: "28px",
    },
    missingAlert: {
      margin: 0,
      marginLeft: 25,
      justifySelf: "flex-start",
    },
    uploadCodeButton: {
      marginLeft: "auto",
      marginRight: 10,
    },
    testCodeButton: {
      marginRight: 10
    },
    saveCodeButton: {
      marginRight: 0,
      marginLeft: 10,
    },
    ide: {
      flex: "1 0 auto",
      marginTop: 10,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      minHeight: 700,
    },
    projectView: {
      flex: "0 0 250px",
    },
    editor: {
      flex: "1 0 auto",
    },
    logs: {
    },
  });

export default styles;
import React from "react";
import { WorkloadQueryType } from "@data";
import { useWorkloadManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseEditQueryProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  etag?: string;
  query?: string;
  tableName?: string;
  overwrite?: boolean;
  type?: WorkloadQueryType;
}

export interface UseEditQueryModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditQueryActions {
  editQuery: () => void;
  reset: () => void;
}

type Props = UseEditQueryProps;
type Model = UseEditQueryModel;
type Actions = UseEditQueryActions;
type Result = [Model, Actions];

export const useEditQuery = (props: Props): Result => {

  const {
    name,
    query,
    type,
    version,
    etag = "",
    tableName,
    overwrite,
    defaultErrorMessage = "Failed to edit workload query",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() => {
    return WorkloadManagerClient.editWorkloadQueryProperties(name, version, etag, {
      overwrite,
      query,
      queryType: type,
      tableName,
    });
  }, [
    WorkloadManagerClient,
    name,
    version,
    etag,
    overwrite,
    query,
    type,
    tableName,
  ]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: editQuery, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Edit Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editQuery,
    reset
  }), [
    editQuery,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditQuery;

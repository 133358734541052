import { Record } from "immutable";
import { getStringValue } from "@util";

export interface ReleasedWorkloadIdentityAttributes {
  name: string;
  releasedByName: string;
  version: number;
}

export class ReleasedWorkloadIdentity extends Record({
  name: "",
  releasedByName: "",
  version: -1,
}) implements ReleasedWorkloadIdentityAttributes {

  public static EMPTY: ReleasedWorkloadIdentity = new ReleasedWorkloadIdentity();

  public readonly name: string;
  public readonly releasedByName: string;
  public readonly version: number;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getReleasedByName(): string {
    return getStringValue(this.releasedByName);
  }

  public getVersion(): number {
    return this.version;
  }

  public getVersionAsString(): string {
    const version = this.getVersion();
    return version >= 0 ? `${version}` : "";
  }
}

export default ReleasedWorkloadIdentity;

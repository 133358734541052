import { AuditEventAttributes } from "@data";
import {
  createQueryParams,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "./helpers";
import { isEmptyString } from "@util";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export interface GetAuditEventsResponse {
  events?: AuditEventAttributes[];
  lastTimestamp?: string;
}

//
// Query Parameters:
//   - type (optional) — Event type
//   - severity (optional) — Event severity
//   - accountId (optional) — Id of the IoT account
//   - principal (optional) — Event principal
//   - origin (optional) — Event origin
//   - requestId (optional) — Event requestId
//   - startDate (optional) — Start date format: date-time
//   - endDate (optional) — End date format: date-time
//   - limit (optional) — Max number of events to return format: int32
//
export const getEvents = (authToken: string,
                          options: any = {}): Promise<GetAuditEventsResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams(typeof options === "object" ? ({ ...options }) : {});

    const url = `${REGIONAL_API}/security/audit/v1/events${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Get audit events failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getEvent = (authToken: string,
                         eventId: string): Promise<AuditEventAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Event ID", eventId],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/audit/v1/events/${eventId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Get audit event failed for id [${eventId}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

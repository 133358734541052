import { Record } from "immutable";
import { getStringValue } from "@util";
import { DEFAULT_WORKLOAD_TYPE_LABELS, WorkloadType } from "@data/Workload";

export interface WorkloadSummaryAttributes {
  name: string;
  type: WorkloadType;
}

export class WorkloadSummary extends Record({
  name: "",
  type: WorkloadType.NONE,
}) implements WorkloadSummaryAttributes {

  public static EMPTY: WorkloadSummary = new WorkloadSummary();

  public readonly name: string;
  public readonly type: WorkloadType;

  public static from(workloadName: string): WorkloadSummary {

    return new WorkloadSummary({
      name: workloadName,
    });
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getType(): WorkloadType {
    return this.type;
  }

  public getTypeLabel(): string {
    return DEFAULT_WORKLOAD_TYPE_LABELS[this.getType()];
  }
}

export default WorkloadSummary;

import React from "react";

const PreventAccidentalBackNavigation = () => {
  React.useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.deltaX !== 0) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return null;
};

export default PreventAccidentalBackNavigation;
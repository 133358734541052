import { useGetPreference } from "@hooks";
import { useDispatch } from "react-redux";
import { updateFavoriteModules } from "@main/actions";

type Result = () => void;

export const useGetFavorites = (): Result => {

  const dispatch = useDispatch();

  const [, { refresh }] = useGetPreference({
    preferenceName: "favorites",
    onSuccess: (p) => { dispatch(updateFavoriteModules(p.entityValue.modules)); },
  });

  return refresh;
};

export default useGetFavorites;

import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { noop, equalsIgnoreCase, isEmptyString } from "@util";
import { WorkloadQueryEditor } from "@components";
import { QueryWorkloadAttributes, Workload } from "@data";
import { useEditQuery, useGetQueryDatabases, useGetQueryDatabasesTables } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";

export interface WorkloadQueryDialogModel extends PortalModuleDialogModel<string> {
  open?: boolean;
  name?: string;
  version?: number;
  etag?: string;
  workload?: Workload;
  queryData?: QueryWorkloadAttributes;
}

export interface WorkloadQueryDialogActions extends PortalModuleDialogActions<string> {
  setQueryData: (data: QueryWorkloadAttributes) => void;
}

type Props = WithStyles<typeof styles> & WorkloadQueryDialogModel & WorkloadQueryDialogActions;

export const WorkloadQueryDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    name = "",
    version = 1,
    etag = "",
    open = false,
    workload = Workload.EMPTY,
    queryData = workload.toJS().query,
    setQueryData = noop,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  const { query = "", type, overwrite, upload, databaseName, tableName } = queryData;

  const disabled = React.useMemo(() => {
    return isEmptyString(databaseName) ||
      isEmptyString(tableName) ||
      isEmptyString(query);
  }, [query, databaseName, tableName]);

  const [{ databases = [], showLoadingIndicator: loadingDatabases },
    { refresh: loadDatabases }] = useGetQueryDatabases({});

  const [{ tables = [], showLoadingIndicator: loadingTables },
    { refresh: loadTables }] = useGetQueryDatabasesTables({ databaseName });

  const [{ showLoadingIndicator, ...otherModel }, { editQuery, reset }] =
    useEditQuery({
      name,
      version,
      etag,
      ...(workload.getQuery() !== query ? ({ query }) : ({})),
      ...(!equalsIgnoreCase(workload.getQueryType(), type) ? ({ type }) : ({})),
      ...(!equalsIgnoreCase(workload.getTableName(), tableName) ? ({ tableName }) : ({})),
      ...(!equalsIgnoreCase(`${workload.isOverwrite()}`, `${overwrite}`) ? ({ overwrite }) : ({})),
    });

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
  }, [reset, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      open={open}
      className={classnames("workloadQueryDialog", classes.root)}
      title={"Edit Workload Query"}
      loading={showLoadingIndicator}
      continueButtonLabel={"Save"}
      continueButtonDisabled={disabled}
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={editQuery}
      cancel={cancel}
    >
      <div className={classnames("workloadQueryContainer", classes.container)}>
        <React.Fragment>
          <WorkloadQueryEditor
            disableDatabaseName={true}
            disableUpload={true}
            sql={query}
            tables={tables}
            tableName={tableName}
            databases={databases}
            databaseName={databaseName}
            loadingTables={loadingTables}
            loadingDatabases={loadingDatabases}
            overwrite={overwrite}
            queryType={type}
            upload={upload}
            loadTables={loadTables}
            loadDatabases={loadDatabases}
            setQueryData={(attrs) => {
              setQueryData({
                ...queryData,
                ...attrs,
              });
            }}
          />
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadQueryDialog;

import React from "react";
import { ListReleasedWorkloadsResponse } from "@network";
import usePaginatedApiRequest, {
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks/use-paginated-api-request";
import useWorkloadManagerClient from "../use-workload-manager-client";
import ReleasedWorkloadIdentity from "@data/ReleasedWorkloadIdentity";

type SuccessResponse = ListReleasedWorkloadsResponse;

export interface UseGetReleasedWorkloadsProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  nameFilter?: string;
  releasedByNameFilter?: string;
  workloads?: ReleasedWorkloadIdentity[];
}

export interface UseGetReleasedWorkloadsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  workloads: ReleasedWorkloadIdentity[];
}

export interface UseGetReleasedWorkloadsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseGetReleasedWorkloadsProps;
type Model = UseGetReleasedWorkloadsModel;
type Actions = UseGetReleasedWorkloadsActions;
type Result = [Model, Actions];

export const useGetReleasedWorkloads = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get released workloads",
    nameFilter,
    releasedByNameFilter,
    workloads: initialWorkloads = [],
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const [workloads, setWorkloads] = React.useState<ReleasedWorkloadIdentity[]>(initialWorkloads);

  const makeApiRequest = React.useCallback((accessToken, next) => {
      return WorkloadManagerClient.getReleasedWorkloadsApi(nameFilter, releasedByNameFilter, next);
  }, [WorkloadManagerClient, nameFilter, releasedByNameFilter]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedWorkloads = React.useMemo<ReleasedWorkloadIdentity[]>(() => {
    const { items = [] } = successResponse ? successResponse : {};
    return items.map(attrs => new ReleasedWorkloadIdentity(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setWorkloads([]);
    baseReset();
  }, [setWorkloads, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  React.useEffect(() => {
    refresh();
  }, [
    refresh,
    nameFilter,
    releasedByNameFilter,
  ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    workloads,
  }), [
    baseModel,
    workloads,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setWorkloads(currentWorkloads => currentWorkloads.concat(updatedWorkloads));
  }, [setWorkloads, updatedWorkloads]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetReleasedWorkloads;

import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EditorViewItem from "./models/EditorViewItem";
import EditorTabs from "./EditorTabs";
import CodeEditor from "./CodeEditor";
import styles from "./styles";

export interface EditorViewModel {
  className?: string;
  items?: EditorViewItem[];
  readonlyMode?: boolean;
  emptyViewTitle?: string;
  emptyViewSubtitle?: string;
}

export interface EditorViewActions {
  closeTab?: (id: string) => void;
  setSelectedTab?: (id: string) => void;
  updateCode?: (id: string, code: string) => void;
}

type Model = EditorViewModel;
type Actions = EditorViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditorView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    items = [],
    emptyViewTitle = "No File Selected",
    emptyViewSubtitle = "Select a file to start editing",
    readonlyMode = false,
    closeTab = noop,
    setSelectedTab = noop,
    updateCode = noop,
    children,
  } = props;

  const selectedTab = React.useMemo(() => {
    return items.find(item => item.isSelected()) || EditorViewItem.EMPTY;
  }, [items]);

  const selectedTabId = React.useMemo(() =>
    selectedTab.getId(), [selectedTab]);

  const selectedTabCode = React.useMemo(() =>
    selectedTab.getCode(), [selectedTab]);

  const showEmptyView = React.useMemo(() =>
    selectedTab === EditorViewItem.EMPTY, [selectedTab]);

  const setCode = React.useCallback(code => {
    if (selectedTabId) {
      updateCode(selectedTabId, code);
    }
  }, [selectedTabId, updateCode]);

  return (
    <div className={classnames("editorView", className, classes.container)}>
      <EditorTabs
        className={classnames("editorTabs", classes.editorTabs)}
        items={items}
        openTab={setSelectedTab}
        closeTab={closeTab}
      />
      {showEmptyView && (
        <div className={classnames("emptyView", classes.emptyView)}>
          <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)}>
            {emptyViewTitle}
          </Typography>
          <Typography className={classnames("emptyViewSubtitle", classes.emptyViewSubtitle)}>
            {emptyViewSubtitle}
          </Typography>
        </div>
      )}
      {!showEmptyView && (
        <CodeEditor
          className={classnames("codeEditor", classes.codeEditor)}
          code={selectedTabCode}
          readOnly={readonlyMode}
          setCode={setCode}
        />
      )}
      {children}
    </div>
  );
});

export default EditorView;